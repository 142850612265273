import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactPlayer from 'react-player/youtube'

import PlayIcon from '~components/PlayIcon'

import * as style from './CardVideo.module.scss'

const CardVideo = (props) => {
  const {
    className,
    videoUrl,
    thumbnailUrl,
    onPlay,
    onPause,
    onEnded,
    playerRef,
    ...rest
  } = props

  const youtubeConfig = {
    playerVars: {
      // This parameter lets you use a YouTube player that does not show a YouTube logo
      modestbranding: 1,
      // This parameter controls whether videos play inline or fullscreen on iOS
      playsinline: 0,
      // This parameter indicates whether the player should show related videos
      // when playback of the initial video ends
      rel: 0,
    },
  }

  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const handleStartPlaying = () => {
    setIsVideoPlaying(true)
    onPlay()
  }
  const handleOnPause = () => {
    setIsVideoPlaying(false)
    onPause()
  }
  const handleOnEnded = () => {
    setIsVideoPlaying(false)
    onEnded()
  }

  return (
    <div {...rest} className={classNames(style.videoWrapperOuter, className)}>
      <div
        className={classNames(style.videoWrapperInner, {
          [style.glowVisible]: !isVideoPlaying,
        })}
      >
        <ReactPlayer
          url={videoUrl}
          light={thumbnailUrl || true}
          config={{ youtube: youtubeConfig }}
          className={style.playerRoot}
          width={null}
          height={null}
          controls
          onPlay={handleStartPlaying}
          onPause={handleOnPause}
          onEnded={handleOnEnded}
          playing={isVideoPlaying}
          playIcon={<PlayIcon variant="landing" />}
          ref={playerRef}
        />
      </div>
    </div>
  )
}

CardVideo.defaultProps = {
  className: '',
  onPlay: () => {},
  onPause: () => {},
  onEnded: () => {},
  playerRef: null,
  thumbnailUrl: undefined,
}

CardVideo.propTypes = {
  className: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  playerRef: PropTypes.any,
}

export default CardVideo

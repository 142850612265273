import React, { createElement, FC } from 'react'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Social from '~components/Social'
import BlockassetLogo from '~components/BlockassetLogo'
import { isAbsoluteUrl } from '~utils'

import { BOTTOM_NAV, LINKS } from './constants'
import * as style from './style.module.scss'
import { useOverlay } from '~providers/OverlayStateProvider'

const Footer: FC = () => {
  const fullYear = new Date().getFullYear()
  const { showModalComingSoon } = useOverlay()
  return (
    <footer className={style.footer}>
      <Container className={classNames('text-syne-14', style.footerInner)}>
        <div className={style.footerTop}>
          <div className={classNames(style.blockFooterBox, style.blockLogo)}>
            <BlockassetLogo variant="footer" />
            <Social variant="footer" />
          </div>
          {BOTTOM_NAV.map(({ heading, href, subItems, isComingSoon }) => {
            const isAbsoluteLink = isAbsoluteUrl(href || '')

            return (
              <div key={heading} className={style.blockFooterBox}>
                {createElement(
                  // eslint-disable-next-line no-nested-ternary
                  href ? (isAbsoluteLink ? 'a' : Link) : 'p',
                  {
                    className: classNames(
                      'text-syne-18-bold',
                      style.navHeading
                    ),
                    to: !isAbsoluteLink ? href : null,
                    href: isAbsoluteLink ? href : null,
                    onClick: (e: any) => {
                      if (isComingSoon) {
                        e.preventDefault()
                        showModalComingSoon(!!heading)
                      }
                    },
                  } as any,
                  heading
                )}
                <ul className={style.topNavLinksList}>
                  {subItems?.map((subItem) => {
                    const isAbsoluteLinkSubItem = isAbsoluteUrl(subItem.href)

                    return (
                      <li key={subItem.text}>
                        {createElement(
                          isAbsoluteLinkSubItem ? 'a' : Link,
                          {
                            to: !isAbsoluteLinkSubItem ? subItem.href : null,
                            href: isAbsoluteLinkSubItem ? subItem.href : null,
                            onClick: (e: any) => {
                              if (subItem.isComingSoon) {
                                e.preventDefault()
                                showModalComingSoon(!!subItem.text)
                              }
                            },
                          } as any,
                          subItem.text
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>
        <div className={style.footerBottom}>
          <p>
            &copy; {fullYear} Blockasset. <span>All rights reserved</span>
          </p>
          <ul className={style.bottomLinks}>
            {LINKS.map(({ name, link }) => (
              <li className={style.bottomLinksItem} key={name}>
                <a
                  className={style.bottomLinksLink}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href={link}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, Button, Dropdown, Nav } from 'react-bootstrap'
import { Link, NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useWallet } from '@solana/wallet-adapter-react'

import Icon from '~components/Icon'
import { TOP_NAV_ITEMS } from '~utils/constants'
import { normalizeUrl, isAbsoluteUrl } from '~utils'
import { useOverlay } from '~providers/OverlayStateProvider'

import ButtonConnect from '../ButtonConnect'
import { DROPDOWN_WALLET_ITEMS } from '../DropdownWallet/constants'
import BlockTokenPrice from '../BlockTokenPrice'
import ButtonCopyWalletAddress from '../ButtonCopyWalletAddress'
import * as style from './HeaderNavMobile.module.scss'

const HeaderNavMobile = (props) => {
  const { className, ...rest } = props

  const { pathname } = useLocation()
  const { connected: isLoggedIn, disconnect } = useWallet()
  const { showModalComingSoon } = useOverlay()

  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false)
  const [accordionActiveKey, setAccordionActiveKey] = useState()

  const handleWalletMenuSelect = (eventKey) => {
    switch (eventKey) {
      case 'logout':
        disconnect()
        setIsDropdownExpanded(false)
        break
      default:
        setIsDropdownExpanded(false)
    }
  }

  const updateAccordionExpandedItem = () => {
    const activeItem = TOP_NAV_ITEMS.find(({ subItems }) => {
      return !!subItems?.find(
        (subItem) => normalizeUrl(subItem.href) === normalizeUrl(pathname)
      )
    })
    setAccordionActiveKey(activeItem?.text)
  }

  useEffect(() => updateAccordionExpandedItem(), [pathname, isDropdownExpanded])

  return (
    <Dropdown
      {...rest}
      className={className}
      onToggle={(isOpen) => setIsDropdownExpanded(isOpen)}
      show={isDropdownExpanded}
    >
      <Dropdown.Toggle
        className="dropdown-toggle-dark-header-mobile-nav"
        as="button"
      >
        <Icon
          name={isDropdownExpanded ? 'icon-menu-close' : 'icon-menu'}
          size={24}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        variant="dark-header-mobile-nav"
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
        }}
      >
        <div className={style.headerMobileTop}>
          {isLoggedIn ? (
            <>
              <BlockTokenPrice variant="standalone" />
              <ButtonCopyWalletAddress />
            </>
          ) : (
            <ButtonConnect />
          )}
        </div>
        <Accordion
          className="accordion-mobile-nav"
          activeKey={accordionActiveKey}
          onSelect={(newActiveKey) => setAccordionActiveKey(newActiveKey)}
        >
          {TOP_NAV_ITEMS.map(
            ({ text, href, subItems, isComingSoon, isExternal }) => {
              const isAbsoluteLink = isAbsoluteUrl(href)

              return subItems?.length ? (
                <Accordion.Item eventKey={text} key={text}>
                  <Accordion.Header>{text}</Accordion.Header>
                  <Accordion.Body>
                    <Nav variant="aside" activeKey="">
                      {subItems.map((subItem) => {
                        const isAbsoluteLinkSubItem = isAbsoluteUrl(href)

                        return (
                          <Nav.Link
                            key={subItem.text}
                            as={isAbsoluteLinkSubItem ? 'a' : NavLink}
                            href={isAbsoluteLinkSubItem ? subItem.href : null}
                            to={!isAbsoluteLinkSubItem ? subItem.href : null}
                            eventKey={subItem.text}
                            disabled={subItem.requiresLogIn && !isLoggedIn}
                            onClick={(e) => {
                              if (subItem.isComingSoon) {
                                e.preventDefault()
                                showModalComingSoon(text)
                              }
                            }}
                          >
                            {subItem.text}
                          </Nav.Link>
                        )
                      })}
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <Dropdown.Item
                  key={text}
                  href={isAbsoluteLink ? href : null}
                  to={!isAbsoluteLink ? href : null}
                  as={isAbsoluteLink ? 'a' : Link}
                  onClick={(e) => {
                    if (isComingSoon) {
                      e.preventDefault()
                      showModalComingSoon(text)
                    }
                  }}
                  target={isExternal ? '_blank' : null}
                >
                  {text}
                </Dropdown.Item>
              )
            }
          )}
        </Accordion>
        {isLoggedIn && (
          <>
            <hr className={classNames('hr-card', style.hrWalletActions)} />
            <ul
              className={classNames(
                'text-syne-16-semi-bold',
                style.dropdownWalletActions
              )}
            >
              {DROPDOWN_WALLET_ITEMS.map(
                ({ key, text, icon, showInMobileMenu }) =>
                  showInMobileMenu ? (
                    <li key={key}>
                      <button
                        type="button"
                        onClick={() => handleWalletMenuSelect(key)}
                      >
                        <Icon name={icon} size={24} />
                        <span>{text}</span>
                      </button>
                    </li>
                  ) : null
              )}
            </ul>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

HeaderNavMobile.defaultProps = {
  className: '',
}

HeaderNavMobile.propTypes = {
  className: PropTypes.string,
}

export default HeaderNavMobile

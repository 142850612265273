import React, { FC } from 'react'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import { Routes, Route, Navigate } from 'react-router-dom'

import RouteURL from '~routes'

import BlockTokenStaking from './components/BlockTokenStaking'
import BlockNftStaking from './components/BlockNftStaking'
import * as style from './AssetHub.module.scss'
import BlockNftStaked from './components/BlockNftStaked'
import { useOverlay } from '~providers/OverlayStateProvider'
import AppLoadingOverlay from '~components/AppLoadingOverlay'

export type AssetHubProps = {
  className?: string
}
const AssetHub: FC<AssetHubProps> = ({ className = '' }) => {
  const { isLoading } = useOverlay()

  return (
    <>
      <AppLoadingOverlay
        active={isLoading}
        spinner
        text="Waiting for Solana blockchain transaction to complete..."
      >
        <div className={style.pageWrapper}>
          <Container>
            <div className={classNames(style.assetHub, className)}>
              <Routes>
                <Route
                  path={RouteURL.TOKEN_STAKING}
                  element={<BlockTokenStaking />}
                />
                <Route
                  path={RouteURL.NFT_STAKING}
                  element={<BlockNftStaking />}
                />
                <Route
                  path={RouteURL.NFT_STAKED}
                  element={<BlockNftStaked />}
                />
                {/* <Route
                        path={RouteURL.TOKEN_STAKING_V2}
                        element={<BlockTokenStakingV2 />}
                      />
                      <Route
                        path={RouteURL.NFT_STAKING_V2}
                        element={<BlockNftStakingV2 />}
                      />
                      <Route
                        path={RouteURL.NFT_STAKED_V2}
                        element={<BlockNftStakedV2 />}
                      />
                      <Route
                        path={RouteURL.SMESH_STAKING}
                        element={<BlockSmeshStaking />}
                      />
                      <Route
                        path={RouteURL.SMESH_STAKED}
                        element={<BlockSmeshStaked />}
                      /> */}
                <Route
                  path="*"
                  element={<Navigate to={RouteURL.LANDING_PAGE} replace />}
                />
              </Routes>
            </div>
          </Container>
        </div>
      </AppLoadingOverlay>
    </>
  )
}

export default AssetHub

/* eslint-disable camelcase */

import { QueryClient } from 'react-query'
import axios from 'axios'

export const queryClient = new QueryClient({})
export const REFETCH_INTERVAL = 60 * 1000 // 1min = 60s = 60 * 1000ms

export const ApiUrl = {
  COINGECKO_PRICE: 'https://api.coingecko.com/api/v3/simple/price',
  MAGICEDEN_BLOCKASSET_LEGENDS_STATS:
    'https://api-mainnet.magiceden.dev/v2/collections/blockasset_legends/stats',
}

export const QueryKey = {
  BLOCK_PRICE: 'blockPrice',
  SOL_PRICE: 'solPrice',
  MAGICEDEN_BLOCKASSET_LEGENDS_STATS: 'magicedenBlockassetLegendsStats',

  BLOCKCHAIN_NFT_GEMS_STAKED: 'nftGemsStaked',
  BLOCKCHAIN_TOKEN_GEMS_STAKED: 'tokenGemsStaked',
}

export const getTokenPrice = (ofToken: string, vsCurrencies: string) =>
  axios
    .get(ApiUrl.COINGECKO_PRICE, {
      params: { ids: ofToken, vs_currencies: vsCurrencies },
    })
    .then((response) => response.data)
    .then((response) => response[ofToken].usd)

export const getBlockassetLegendsStats = () => {
  axios
    .get(ApiUrl.MAGICEDEN_BLOCKASSET_LEGENDS_STATS)
    .then((response) => response.data)
}

// extracted by mini-css-extract-plugin
export var blockTokenPrice = "BlockTokenPrice_blockTokenPrice__Crvbw";
export var dotty = "BlockTokenPrice_dotty__Xl9QB";
export var dropdown = "BlockTokenPrice_dropdown__mqbqw";
export var dropdownOpen = "BlockTokenPrice_dropdownOpen__YdcKy";
export var iconChevron = "BlockTokenPrice_iconChevron__lgOxU";
export var logo = "BlockTokenPrice_logo__xBICg";
export var rotate = "BlockTokenPrice_rotate__E5a-g";
export var standalone = "BlockTokenPrice_standalone__7WupO";
export var textLabel = "BlockTokenPrice_textLabel__CJJSp";
export var textPrice = "BlockTokenPrice_textPrice__O6ClA";
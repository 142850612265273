export const AuctionHistoryItemType = {
  MINT: 'MINT',
  PURCHASE: 'PURCHASE',
}

export const AuctionStatus = {
  ONSALE: 'on_sale',
  SOLD: 'sold',
  OWNED: 'owned',
}

export const SearchItemsType = {
  LEGENDS: 'Legends',
  ITEMS: 'Items',
  USERS: 'Users',
}

/* eslint-disable prettier/prettier */
export const GEMFARM_ERROR_CODES = [
  "Failed to perform some math operation safely",
  "Unknown instruction called",
  "Invalid parameter passed",
  "Anchor serialization issue",
  "Two amounts that are supposed to be equal are not",
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  "Vault is currently locked or frozen and cannot be accessed",
  "Vault doesnt't containt any gems",
  "This gem is not present on any of the whitelists",
  "Whitelist proof exists but for the wrong type",
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  "Passed in reward mint is not available for this farm",
  "The reward is locked and cannot be cancelled",
  "Can't unstake, minimum staking period has not passed yet",
  "Can't unstake, cooldown period has not passed yet",
  null,
  "Reward has insufficient funding, please top up",
  "Update authority passed doesnt match that stored in metadata",
  "Wrong metadata account, gem mint doesn't match",
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
]

import { AnchorProvider } from '@project-serum/anchor'
import { WalletContextState } from '@solana/wallet-adapter-react'
import {
  Signer,
  TransactionInstruction,
  VersionedTransaction,
  TransactionMessage,
  Connection,
  AddressLookupTableAccount,
  ConfirmOptions,
} from '@solana/web3.js'

type BuildAndSendTxArgs = {
  connection: Connection
  wallet: WalletContextState
  ixs: TransactionInstruction[]
  extraSigners?: Signer[]
  opts?: ConfirmOptions
  // Optional, if present signify that a V0 tx should be sent
  lookupTableAccounts?: [AddressLookupTableAccount] | undefined
}

export async function buildAndSendTx({
  connection,
  wallet,
  ixs,
  extraSigners = [],
  opts = {},
  lookupTableAccounts,
}: BuildAndSendTxArgs) {
  if (!wallet.publicKey || !wallet.signTransaction)
    throw new Error('Wallet not connected')
  try {
    const { blockhash, lastValidBlockHeight } =
      await connection.getLatestBlockhash('max')

    const messageV0 = new TransactionMessage({
      payerKey: wallet.publicKey,
      recentBlockhash: blockhash,
      instructions: ixs,
    }).compileToV0Message(lookupTableAccounts)
    const transaction = new VersionedTransaction(messageV0)
    if (extraSigners?.length) transaction.sign(extraSigners)

    const signedTransaction = await wallet.signTransaction(transaction)

    const signature = await connection.sendTransaction(signedTransaction, opts)
    await connection.confirmTransaction(
      {
        blockhash,
        signature,
        lastValidBlockHeight,
      },
      opts?.commitment || 'confirmed'
    )
    console.info(`🎉 https://explorer.solana.com/tx/${signature}`)
    return signature
  } catch (error) {
    console.error(error)
    throw error
  }
}

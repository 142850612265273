import React, { FC } from 'react'
import classNames from 'classnames'

import BlockassetTokenIcon from '~components/BlockassetTokenIcon'
import useBlockPrice from '~hooks/useBlockPrice'

import * as style from './BlockTokenPrice.module.scss'

export type BlockTokenPriceProps = {
  className?: string
}
export const BlockTokenPrice: FC<BlockTokenPriceProps> = ({
  className = '',
}) => {
  const blockPrice = useBlockPrice()

  return (
    <div className={classNames(style.blockTokenPrice, className)}>
      <BlockassetTokenIcon width="24px" height="24px" className={style.logo} />
      <span className={style.textLabel}>PRICE</span>
      <div className={classNames('text-joyride-13', style.blockPrice)}>
        <span className={style.textPrice}>
          ${blockPrice ? blockPrice.toFixed(2) : '--'}
        </span>
        /BLOCK
      </div>
    </div>
  )
}

export default BlockTokenPrice

import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import RouteURL from '~routes'

import * as style from './BannerYG.module.scss'

export type BannerYGProps = {
  className?: string
  variant?: 'staked' | 'legendsStaking' | 'smeshStaking' | 'landing'
  assetBalance?: number
  blockBalance?: number
  claim?: () => void
  hideClaimButton?: boolean
}

export const BannerYG: FC<BannerYGProps> = ({
  className = '',
  variant = 'staked',
  assetBalance = 0,
  blockBalance = 0,
  claim = () => {},
  hideClaimButton = false,
}) => {
  const variantStyle =
    variant === 'legendsStaking' || variant === 'smeshStaking'
      ? 'staking'
      : variant
  return (
    <div className={classNames(style.banner, className)}>
      <div className={style.bannerInner}>
        {/* 🏳️ BANNER HEADING variants ⤵️ */}
        {variant === 'legendsStaking' && (
          <span className={classNames('text-joyride-14', style.textTopStaking)}>
            RARITY BASED LEGEND NFT REWARDS, PAID OUT PER SECOND
          </span>
        )}
        {variant === 'smeshStaking' && (
          <span className={classNames('text-joyride-14', style.textTopStaking)}>
            RARITY BASED SMESH BROS NFT REWARDS, PAID OUT PER SECOND
          </span>
        )}
        {variant === 'staked' && (
          <span className={classNames('text-joyride-14', style.textTopStaked)}>
            STAKING REWARDS
          </span>
        )}
        {variant === 'landing' && (
          <span className={classNames('text-joyride-14', style.textTopStaked)}>
            BLOCK & NFT STAKING
          </span>
        )}
        {/* 🏳️ BANNER HEADING variants ⤴️ */}

        {/* 🟨 YELLOW BLOCK ⤵️ */}
        <div
          className={classNames(
            variant === 'landing' ? 'text-joyride-20' : 'text-joyride-28',
            style.blockInfo,
            style.blockYellow,
            { [style[variantStyle]]: true }
          )}
        >
          {variant === 'legendsStaking' && (
            <>
              <span>EARN</span>
              <span>8-24</span>
              <span
                className={classNames(
                  'text-joyride-10',
                  'text-color-gradient-yellow',
                  style.textYellow
                )}
              >
                $ASSET/DAY
              </span>
            </>
          )}
          {variant === 'smeshStaking' && (
            <>
              <span>EARN</span>
              <span>-</span>
              <span
                className={classNames(
                  'text-joyride-10',
                  'text-color-gradient-yellow',
                  style.textYellow
                )}
              >
                $ASSET/DAY
              </span>
            </>
          )}
          {variant === 'staked' && (
            <>
              <span className={style.textGray}>ASSET BALANCE</span>
              <span>{(assetBalance || 0).toFixed(4)}</span>
            </>
          )}
          {variant === 'landing' && (
            <>
              <span className="text-joyride-15">NFT STAKING</span>
              <div className={style.landingValuesGrid}>
                <span
                  className={classNames(
                    'text-joyride-12',
                    style.landingTextGray
                  )}
                >
                  DAILY REWARD
                </span>
                <span className="text-color-gradient-yellow">
                  8-24 ASSET PER NFT
                </span>
              </div>
            </>
          )}
        </div>
        {/* 🟨 YELLOW BLOCK ⤴️ */}

        {/* 🟩 GREEN BLOCK ⤵️ */}
        <div
          className={classNames(
            variant === 'landing' ? 'text-joyride-20' : 'text-joyride-28',
            style.blockInfo,
            style.blockGreen,
            { [style[variantStyle]]: true }
          )}
        >
          {variant === 'legendsStaking' && (
            <>
              <span>EARN</span>
              <span>-</span>
              <span className={style.textGray}>
                {/* FIRST 30 DAYS OF ASSET HUB LIVE */}
              </span>
              <span className="text-joyride-10 text-color-gradient-primary">
                $BLOCK/DAY
              </span>
            </>
          )}
          {variant === 'smeshStaking' && (
            <>
              <span>EARN</span>
              <span>2-3</span>
              <span className={style.textGray}></span>
              <span className="text-joyride-10 text-color-gradient-primary">
                $BLOCK/DAY
              </span>
            </>
          )}
          {variant === 'staked' && (
            <>
              <span className={style.textGray}>BLOCK BALANCE</span>
              <span>{(blockBalance || 0).toFixed(5)}</span>
            </>
          )}
          {variant === 'landing' && (
            <>
              <span className="text-joyride-15">BLOCK STAKING</span>
              <div className={style.landingValuesGrid}>
                <span
                  className={classNames(
                    'text-joyride-12',
                    style.landingTextGray
                  )}
                >
                  DAILY ROI
                </span>
                <span className="text-color-gradient-primary">0.082%</span>
                <span
                  className={classNames(
                    'text-joyride-12',
                    style.landingTextGray
                  )}
                >
                  APY
                </span>
                <span className="text-color-gradient-primary">30%</span>
              </div>
            </>
          )}
        </div>
        {/* 🟩 GREEN BLOCK ⤴️ */}
      </div>

      {/* 🔘 BUTTON variants ⤵️ */}
      {variant === 'legendsStaking' && (
        <Button
          variant="yellow-green"
          className={style.button}
          onClick={() => {
            window.open(
              'https://www.magiceden.io/marketplace/blockasset_legends'
            )
          }}
        >
          GO TO NFT MARKETPLACE
        </Button>
      )}
      {variant === 'smeshStaking' && (
        <Button
          variant="yellow-green"
          className={style.button}
          onClick={() => {
            window.open('https://www.magiceden.io/marketplace/smeshbros')
          }}
        >
          GO TO NFT MARKETPLACE
        </Button>
      )}
      {variant === 'staked' && !hideClaimButton && (
        <Button
          variant="yellow-green"
          className={style.button}
          disabled={assetBalance === 0 && blockBalance === 0}
          onClick={claim}
        >
          CLAIM REWARDS
        </Button>
      )}
      {variant === 'landing' && (
        <Button
          variant="yellow-green"
          className={style.button}
          as={Link as any}
          to={RouteURL.TOKEN_STAKING}
        >
          GO TO ASSET HUB
        </Button>
      )}
      {/* 🔘 BUTTON variants ⤴️ */}
    </div>
  )
}

export default BannerYG

import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react'
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'

import './styles/app.scss'

import RouteURL from '~routes'
import AssetHub from '~containers/AssetHub'
import LandingPage from '~containers/LandingPage'

import Layout from '~components/Layout'
import { UTCNowProvider } from '~providers/UTCNowProvider'

import '@solana/wallet-adapter-react-ui/styles.css'
import { useOverlay } from '~providers/OverlayStateProvider'
import { configs } from '~configs'

const App = () => {
  const { pathname } = useLocation()
  const { setIsLoading } = useOverlay()

  const network = configs.network
  const endpoint = configs.clusterApiUrl

  const wallets = [
    new PhantomWalletAdapter({ network }),
    new SolflareWalletAdapter({ network }),
  ]

  const walletError = () => {
    setIsLoading(false)
  }

  // Restore scroll on location change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <ConnectionProvider endpoint={endpoint}>
      <UTCNowProvider>
        <WalletProvider wallets={wallets} autoConnect onError={walletError}>
          <WalletModalProvider>
            <Layout>
              <Routes>
                <Route path={RouteURL.LANDING_PAGE} element={<LandingPage />} />
                {/* The `/*` route, so has nomatch navigate inside */}
                <Route path="/*" element={<AssetHub />} />
              </Routes>
            </Layout>
          </WalletModalProvider>
        </WalletProvider>
      </UTCNowProvider>
    </ConnectionProvider>
  )
}

export default App

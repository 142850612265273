import React, { FC } from 'react'
import classNames from 'classnames'

import BlockassetTokenIcon from '~components/BlockassetTokenIcon'

import * as style from './BlockBalance.module.scss'
import { useTokenBalance } from '~hooks/useTokenBalance'

export type BlockBalanceProps = {
  className?: string
  variant: 'borderless' | 'copy' | ''
}
export const BlockBalance: FC<BlockBalanceProps> = ({
  className = '',
  variant = '',
}) => {
  const { blockBalance } = useTokenBalance()

  return (
    <div
      className={classNames(
        style.blockBalance,
        { [style[variant]]: variant },
        className
      )}
    >
      <BlockassetTokenIcon width="24px" height="24px" className={style.logo} />
      <span className={style.textLabel}>YOUR BALANCE</span>
      <div className="text-joyride-13">
        {blockBalance?.amount?.toFixed(2)} BLOCK
      </div>
    </div>
  )
}

export default BlockBalance

/* eslint-disable react/jsx-no-useless-fragment,no-nested-ternary */

import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import BlockassetLogo from '~components/BlockassetLogo'
import { isLandingPageNavigation } from '~utils'

import HeaderNavMenu from './components/HeaderNavMenu'
import DropdownWallet from './components/DropdownWallet'
import ButtonConnect from './components/ButtonConnect'
import HeaderNavMobile from './components/HeaderNavMobile'

import * as style from './Header.module.scss'

const Header = () => {
  const { connected } = useWallet()
  const { setVisible } = useWalletModal()

  const { pathname } = useLocation()

  const isLandingPageNav = isLandingPageNavigation(pathname)

  const isDXl = useMediaQuery({
    query: '(max-width: 1199.98px)',
  })

  const isUpMd = useMediaQuery({
    query: '(min-width: 640px)',
  })

  return (
    <header className={style.headerWrapper} id="header">
      <Container className={style.header}>
        <BlockassetLogo variant="header" />
        <HeaderNavMenu className={style.navMenu} />
        <div className={style.gridHeaderRight}>
          {isUpMd ? (
            connected ? (
              <DropdownWallet />
            ) : isLandingPageNav ? (
              <ButtonConnect />
            ) : (
              <Button
                variant="neon"
                onClick={() => setVisible(true)}
                className={classNames(
                  'text-joyride-10',
                  style.btnConnectWallet
                )}
              >
                CONNECT WALLET
              </Button>
            )
          ) : null}
          {isDXl && <HeaderNavMobile />}
        </div>
      </Container>
    </header>
  )
}

export default Header

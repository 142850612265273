import { PublicKey } from '@solana/web3.js'

export const DEFAULTS = {
  CLUSTER: 'mainnet',
  BLOCK_FARM: new PublicKey('HEfEYguwugvbEMYSKaNrkVw4x32y57Zk1SzABbJUA22C'),
  BLOCK_MINT: new PublicKey('NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk'),
  LAMPORTS_PER_BLOCK: 10 ** 6,

  NFT_FARM: new PublicKey('6WrPoubFtDvaQbhKohjAHKPoP4fmKwsPuEoRe8D2Mx7J'),
  LEGENDS_CM: new PublicKey('GghbWF9xtKmJCp7JMSo4HNZ8dwGkSjRyWm1vNC58jZES'),
  EXILED_CM: new PublicKey('H2oh994VjarQ5m69wUSaahQrHLefmjRa1WoipV7brWqd'),

  GEM_BANK_PROG_ID: new PublicKey(
    'bankHHdqMuaaST4qQk6mkzxGeKPHWmqdgor6Gs8r88m'
  ),
  GEM_FARM_PROG_ID: new PublicKey(
    'farmL4xeBFVXJqtfxCzU9b28QACM7E2W2ctT6epAjvE'
  ),
}

// dev DEFAULTS, uncomment for developing
// export const DEFAULTS = {
//   CLUSTER: 'devnet',
//   BLOCK_FARM: new PublicKey('72KaJGPM8rCiGVGQVjnNshvqQ2mFg8mutxDcF7Tyfn53'),
//   BLOCK_MINT: new PublicKey('7skSf6aw7bfnt3Lt4Kih52UMenDqA15peqdqa4vRdXYk'),

//   NFT_FARM: new PublicKey('9jUU8gH1nPBZmphsMhTVeFAvkgH9wxtYkHzM9RqNPhD3'), //
//   LEGENDS_CM: new PublicKey('74jx3Jcj3LzVAaL6dp8fiJzZdTauuxGYoHskdAEzApbX'),

//   GEM_BANK_PROG_ID: new PublicKey(
//     'bankHHdqMuaaST4qQk6mkzxGeKPHWmqdgor6Gs8r88m'
//   ),
//   GEM_FARM_PROG_ID: new PublicKey(
//     'farmL4xeBFVXJqtfxCzU9b28QACM7E2W2ctT6epAjvE'
//   ),
// }

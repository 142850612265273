import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import { useWallet } from '@solana/wallet-adapter-react'
import Icon from '~components/Icon'
import { useOverlay } from '~providers/OverlayStateProvider'
import { isAbsoluteUrl } from '~utils'

const HeaderNavDropdown = (props) => {
  const { className, text: parentText, subItems, ...rest } = props

  const { connected: isLoggedIn } = useWallet()

  const { showModalComingSoon } = useOverlay()

  return (
    <div>
      <Dropdown {...rest} className={className}>
        <Dropdown.Toggle
          as="button"
          className="dropdown-toggle-dark-header-nav"
        >
          <span>{parentText}</span>
          <Icon name="icon-chevron-down-wallet" size={12} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          variant="dark-header-nav"
          popperConfig={{
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          }}
        >
          <Nav variant="aside" activeKey="">
            {subItems.map(({ text, href, requiresLogIn, isComingSoon }) => {
              const isAbsoluteLink = isAbsoluteUrl(href)

              return (
                <Nav.Link
                  key={text}
                  as={isAbsoluteLink ? 'a' : NavLink}
                  href={isAbsoluteLink ? href : null}
                  to={!isAbsoluteLink ? href : null}
                  eventKey={href}
                  disabled={requiresLogIn && !isLoggedIn}
                  onClick={(e) => {
                    if (isComingSoon) {
                      e.preventDefault()
                      showModalComingSoon(text)
                    }
                  }}
                >
                  {text}
                </Nav.Link>
              )
            })}
          </Nav>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

HeaderNavDropdown.defaultProps = {
  className: '',
}

HeaderNavDropdown.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, href: PropTypes.string })
  ).isRequired,
}

export default HeaderNavDropdown

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import TagManager from 'react-gtm-module'

import { AuthProvider } from '~hooks/useAuth'
import OverlayStateProvider from '~providers/OverlayStateProvider'
import { queryClient } from '~api'

import App from './App'
import { reportWebVitals } from './reportWebVitals'

const tagManagerArgs = {
  gtmId: 'GTM-524PNGH',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <OverlayStateProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </OverlayStateProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

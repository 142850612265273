import React, { FC, useEffect } from 'react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import classNames from 'classnames'

import * as style from './WalletButton.module.scss'
import { ButtonProps } from 'react-bootstrap'

const WalletButton: FC<ButtonProps> = ({ className = '', ...rest }) => {
  const { connected } = useWallet()

  useEffect(() => {
    const walletBtn = document.querySelector(
      'button.wallet-adapter-button.btn.btn-neon'
    )
    if (walletBtn) walletBtn.classList.remove('wallet-adapter-button')
  }, [connected])

  return (
    <WalletMultiButton
      className={classNames('btn', 'btn-neon', style.btnWallet, className)}
      {...rest}
    />
  )
}

export default WalletButton

import { Connection, PublicKey } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import axios from 'axios'
import { programs } from '@metaplex/js'
import { getBatchedMultipleAccounts } from '@cardinal/common'

const {
  metadata: { Metadata },
} = programs

export interface OnchainMetadata {
  key: number
  updateAuthority: string
  mint: string
  data: Data
  primarySaleHappened: number
  isMutable: number
  editionNonce: number
}

export interface Data {
  name: string
  symbol: string
  uri: string
  sellerFeeBasisPoints: number
  creators: Creator[]
}

export interface Creator {
  address: string
  verified: number
  share: number
}

export interface ExternalMetadata {
  name: string
  symbol: string
  seller_fee_basis_points: number
  image: string
  external_url: string
  description: string
  properties: Properties
  attributes: Attribute[]
}

export interface Attribute {
  trait_type: string
  value: string
}

export interface Properties {
  creators: Creator[]
  files: File[]
}

export interface Creator {
  address: string
  share: number
}

export interface File {
  uri: string
  type: string
}

export interface INFT {
  pubkey?: PublicKey
  mint: PublicKey
  onchainMetadata: OnchainMetadata
  externalMetadata: ExternalMetadata
  frozen: boolean
}

async function getTokensByOwner(owner: PublicKey, conn: Connection) {
  const tokens = await conn.getParsedTokenAccountsByOwner(owner, {
    programId: TOKEN_PROGRAM_ID,
  })

  // initial filter - only tokens with 0 decimals & of which 1 is present in the wallet
  return tokens.value
    .filter((t) => {
      const amount = t.account.data.parsed.info.tokenAmount
      return amount.decimals === 0 && amount.uiAmount === 1
    })
    .map((t) => {
      return {
        pubkey: t.pubkey,
        mint: t.account.data.parsed.info.mint,
        frozen: t.account.data.parsed.info.state === 'frozen',
      }
    })
}

export async function getNFTMetadataForMany(
  tokens: {
    pubkey: PublicKey
    mint: any
    frozen: boolean
  }[],
  conn: Connection
): Promise<INFT[]> {
  const metaplexIds = await Promise.all(
    tokens.map((t) => Metadata.getPDA(t.mint))
  )
  const metaplexAccountInfos = await getBatchedMultipleAccounts(
    conn,
    metaplexIds
  )
  const metaplexData = metaplexAccountInfos.map((accountInfo, i) => {
    try {
      return programs.metadata.MetadataData.deserialize(
        accountInfo?.data as Buffer
      )
    } catch (e) {
      return null
    }
  })

  const metadata = await Promise.all(
    metaplexData.map(async (md) => {
      try {
        if (!md?.data.uri) return null
        return (await axios.get(md?.data.uri)).data
      } catch (e) {
        return null
      }
    })
  )

  const NFTs: INFT[] = []
  tokens.forEach((t, i) => {
    if (
      metaplexData[i] &&
      metadata[i] &&
      metadata[i].attributes &&
      metadata[i].attributes.length > 0
    ) {
      NFTs.push({
        pubkey: t.pubkey,
        mint: new PublicKey(t.mint),
        onchainMetadata: metaplexData[i],
        externalMetadata: metadata[i],
        frozen: t.frozen,
      })
    }
  })

  return NFTs
}

export async function getNFTsByOwner(
  owner: PublicKey,
  conn: Connection
): Promise<INFT[]> {
  const tokens = await getTokensByOwner(owner, conn)

  return await getNFTMetadataForMany(tokens, conn)
}

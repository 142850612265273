import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useWallet } from '@solana/wallet-adapter-react'

import SEO from '~components/SEO'
import WalletButton from '~components/WalletButton'
import { useOverlay } from '~providers/OverlayStateProvider'

import CardStakeBlock from './components/CardStakeBlock'
import CardStakeCalculator from './components/CardStakeCalculator'
import CardTokenInfo from '../CardTokenInfo'
import CardSideNav from '../CardSideNav'

import * as style from './BlockTokenStaking.module.scss'

const APR_AMOUNT = 30

const BlockTokenStaking = (props) => {
  const { className, ...rest } = props

  const { setIsLoading } = useOverlay()

  const wallet = useWallet()

  return (
    <>
      <aside>
        <CardSideNav />
      </aside>
      <SEO
        title="Token Staking | Blockasset"
        description="Stake your $BLOCK tokens and earn rewards!”"
      />
      <div {...rest} className={classNames(style.blockMain, className)}>
        {/* <CardTokenInfo className={style.cardInfo} /> */}
        {wallet.connected ? (
          <CardStakeBlock
            aprAmount={APR_AMOUNT}
            className={style.stakeCalculator}
            setIsLoading={setIsLoading}
          />
        ) : (
          <>
            <WalletButton />
            <CardStakeCalculator
              aprAmount={APR_AMOUNT}
              className={style.stakeCalculator}
            />
          </>
        )}
      </div>
    </>
  )
}

BlockTokenStaking.defaultProps = {
  className: '',
}

BlockTokenStaking.propTypes = {
  className: PropTypes.string,
}

export default BlockTokenStaking

import urlRegex from 'url-regex'
import { trim } from 'lodash'

export const insertMiddleEllipsis = (
  text: string,
  containerWidth: number,
  charWidth = 11,
  ellipsis = '...',
  safetyMargin = 0
) => {
  const textLen = text.length
  const charsFit = Math.floor(containerWidth / charWidth)
  const charsToTrim = Math.max(0, textLen - charsFit + safetyMargin)

  if (!charsToTrim) return text

  const charsToTrimWEllipsis = charsToTrim + 1 + safetyMargin

  const part1 = text.substr(0, Math.floor(textLen / 2))
  const part2 = text.substr(Math.floor(textLen / 2))

  const part1Trimmed = part1.substr(
    0,
    part1.length - Math.floor(charsToTrimWEllipsis / 2)
  )
  const part2Trimmed = part2.substr(Math.ceil(charsToTrimWEllipsis / 2))

  return `${part1Trimmed}${ellipsis}${part2Trimmed}`
}

export const maskNumberValue = (value: string) =>
  value
    .replace(',', '.') // replace `,` with `.`
    .replace(/^\./gm, '0.') // replace `.` with `0.` at the line start
    .replace(/(.*[.].*)([.])/gm, '$1') // `prevent 2 dots (e.g. `123.45.6`)
    .replace(/[^\d.]/gi, '') // allow digits and dots only

export const limitMaxValue = (value: string, max: number) => {
  if (!value.length) return 0
  const val = parseFloat(value)
  return val > max ? max : val
}

export const normalizeUrl = (url: string) => trim(url, '/') || '/'

export const RHF_URL_RULE = {
  pattern: {
    value: urlRegex({ strict: false }),
    message: 'Please, enter a valid url',
  },
}

export const RHF_EMAIL_RULE = {
  pattern: {
    value:
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Please, enter a valid email address',
  },
}

export const getCountdown = (_secs: number) => {
  let secs = _secs

  const years = Math.trunc(secs / (60 * 60 * 24 * 365))
  secs %= 60 * 60 * 24 * 365

  const months = Math.trunc(secs / (60 * 60 * 24 * 30))
  secs %= 60 * 60 * 24 * 30

  const days = Math.trunc(secs / (60 * 60 * 24))
  secs %= 60 * 60 * 24

  const hours = Math.trunc(secs / (60 * 60))
  secs %= 60 * 60

  const minutes = Math.trunc(secs / 60)
  secs %= 60

  const seconds = Math.trunc(secs)

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  }
}

export const getCountdownStr = (countdown: {
  years: number
  months: number
  days: number
  hours: number
  minutes: number
  seconds: number
}) => {
  if (countdown.years > 0)
    return `${countdown.years} year${countdown.years > 1 ? 's' : ''}`
  if (countdown.months > 0)
    return `${countdown.months} month${countdown.months > 1 ? 's' : ''}`
  if (countdown.days > 0)
    return `${countdown.days} day${countdown.days > 1 ? 's' : ''}`
  if (countdown.hours > 0)
    return `${countdown.hours} hour${countdown.hours > 1 ? 's' : ''}`
  if (countdown.minutes > 0)
    return `${countdown.minutes} minute${countdown.minutes > 1 ? 's' : ''}`
  if (countdown.seconds > 0)
    return `${countdown.seconds} second${countdown.seconds > 1 ? 's' : ''}`
  return ''
}

export const kM = (number: number) => {
  if (number < 1) return number.toString()

  const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E']

  // what tier? (determines SI symbol)
  /* eslint-disable-next-line */
  const tier = (Math.log10(Math.abs(number)) / 3) | 0

  // if zero, we don't need a suffix
  if (tier === 0) return number.toFixed(2)

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier]
  const scale = 10 ** (tier * 3)

  // scale the number
  const scaled = number / scale

  // format number and add suffix
  return scaled.toFixed(2) + suffix
}

export const isLandingPageNavigation = (pathname: string) => {
  return pathname === '/'
}

/**
 * @restart/ui: getBodyScrollbarWidth()
 * Same function as used by react-bootstrap
 * Get the width of the vertical window scrollbar if it's visible
 */
export const getBodyScrollbarWidth = (ownerDocument = document) => {
  const window = ownerDocument.defaultView
  return Math.abs(
    (window?.innerWidth || 1000) - ownerDocument.documentElement.clientWidth
  )
}

/**
 * Fast & simple test for http:// and https:// only
 * @param urlString {string}
 * @returns {boolean}
 */
export const isAbsoluteUrl = (urlString: string) =>
  urlString?.indexOf('http://') === 0 || urlString?.indexOf('https://') === 0

import React, { FC, useEffect, useState } from 'react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { useQuery } from 'react-query'
import { useConnection } from '@solana/wallet-adapter-react'

import { initGemFarm } from '~gemworks/gem-farm'
import { DEFAULTS } from '~gemworks/globals'

import useSolPrice from '~hooks/useSolPrice'
import { kM } from '~utils'
import { getTokenPrice, QueryKey, REFETCH_INTERVAL } from '~api'

// import { useLegends } from '~providers/LegendsProvider'
// import { useSmesh } from '~providers/SmeshProvider'

import CardInfo from '../CardInfo'

export const CardNFTInfo: FC = () => {
  const { connection } = useConnection()
  const solPrice = useSolPrice()

  // const { poolActiveStakes: legendsActiveStakes } = useLegends()
  // const { poolActiveStakes: smeshActiveStakes } = useSmesh()

  const { data: nftGemsStaked } = useQuery(
    QueryKey.BLOCKCHAIN_NFT_GEMS_STAKED,
    async () => {
      const gf = await initGemFarm(connection)
      const tempFarmAcc = await gf.fetchFarmAcc(DEFAULTS.NFT_FARM)
      return tempFarmAcc.gemsStaked.toNumber()
    },
    {
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  const { data: blockassetLegendsStats } = useQuery(
    [QueryKey.BLOCK_PRICE],
    () => getTokenPrice('blockasset', 'usd'),
    {
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  const [minValueStaked, setMinValueStaked] = useState(0)

  useEffect(() => {
    if (!nftGemsStaked || !solPrice || !blockassetLegendsStats) return

    const floorPriceUsd =
      (blockassetLegendsStats.floorPrice / LAMPORTS_PER_SOL) * solPrice

    setMinValueStaked(nftGemsStaked * (floorPriceUsd || 180))
  }, [
    nftGemsStaked,
    solPrice,
    blockassetLegendsStats,
    // legendsActiveStakes,
    // smeshActiveStakes,
  ])

  const percentLocked = (((nftGemsStaked || 0) * 100) / (11792 + 4000)).toFixed(
    2
  )
  const minValueStakedString = `$${minValueStaked ? kM(minValueStaked) : '--'}`

  return (
    <CardInfo
      data={[
        {
          label: 'NFT STAKED',
          value: nftGemsStaked || 0,
          iconName: 'layers',
        },
        {
          label: '% LOCKED',
          value: percentLocked,
          iconName: 'percent',
        },
        {
          label: 'NFT VALUE LOCKED',
          value: minValueStakedString,
          iconName: 'dollar',
        },
      ]}
    />
  )
}

export default CardNFTInfo

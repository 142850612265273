import {
  ComputeBudgetProgram,
  Connection,
  PublicKey,
  Signer,
} from '@solana/web3.js'
import { Metaplex } from '@metaplex-foundation/js'
import { PROGRAM_ID as TMETA_PROG_ID } from '@metaplex-foundation/mpl-token-metadata/'
import {
  PREFIX,
  PROGRAM_ID as AUTH_PROG_ID,
} from '@metaplex-foundation/mpl-token-auth-rules'

export const GEM_BANK_PROG_ID = new PublicKey(
  'bankHHdqMuaaST4qQk6mkzxGeKPHWmqdgor6Gs8r88m'
)
export const GEM_FARM_PROG_ID = new PublicKey(
  'farmL4xeBFVXJqtfxCzU9b28QACM7E2W2ctT6epAjvE'
)
export const fetchNft = async (conn: Connection, mint: PublicKey) => {
  const mplex = new Metaplex(conn)
  return await mplex
    .nfts()
    .findByMint({ mintAddress: mint, loadJsonMetadata: true })
}

export const findTokenRecordPDA = (mint: PublicKey, token: PublicKey) => {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata'),
      TMETA_PROG_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from('token_record'),
      token.toBuffer(),
    ],
    TMETA_PROG_ID
  )
}

export const findRuleSetPDA = async (payer: PublicKey, name: string) => {
  return await PublicKey.findProgramAddress(
    [Buffer.from(PREFIX), payer.toBuffer(), Buffer.from(name)],
    AUTH_PROG_ID
  )
}

export const getTotalComputeIxs = (
  compute: number,
  priorityMicroLamports = 1
) => {
  const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
    units: compute,
  })
  const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: priorityMicroLamports,
  })
  return [modifyComputeUnits, addPriorityFee]
}

export type CreatorInput = {
  address: PublicKey
  share: number
  authority?: Signer
}

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import React, { useState, Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useWallet } from '@solana/wallet-adapter-react'
import { BN } from '@project-serum/anchor'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Button } from 'react-bootstrap'
import { findFarmerPDA } from '@gemworks/gem-farm-ts'
import { useConnection } from '@solana/wallet-adapter-react'

import { initGemBank } from '~gemworks/gem-bank'
import { initGemFarm } from '~gemworks/gem-farm'
import { getNFTsByOwner } from '~utils/NFTget'
import { DEFAULTS } from '~gemworks/globals'

import SEO from '~components/SEO'
import WalletButton from '~components/WalletButton'
import { useOverlay } from '~providers/OverlayStateProvider'

import CardNFTInfo from '../CardNFTInfo'
import CardSideNav from '../CardSideNav'
import CardNFT from './components/CardNFT'
import BannerFeatures from './components/BannerFeatures'

import * as metaplex from '@metaplex-foundation/js'

import * as anchor from '@project-serum/anchor'
import {
  findGdrPDA,
  findGemBoxPDA,
  findRarityPDA,
  findVaultAuthorityPDA,
  isKp,
  findWhitelistProofPDA,
} from '@gemworks/gem-farm-ts'

import { findTokenRecordPDA, getTotalComputeIxs } from '~utils/pnft'
import { buildAndSendTx } from '~utils/transaction'

import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import {
  Metadata,
  PROGRAM_ID as TMETA_PROG_ID,
} from '@metaplex-foundation/mpl-token-metadata'
import {
  PublicKey,
  SystemProgram,
  SYSVAR_INSTRUCTIONS_PUBKEY,
} from '@solana/web3.js'
import { PROGRAM_ID as AUTH_PROG_ID } from '@metaplex-foundation/mpl-token-auth-rules'
import * as style from './BlockNftStaking.module.scss'

const StakeCart = React.lazy(() =>
  import(/* webpackChunkName: "lazy-stake-cart" */ './components/StakeCart')
)

const farm = DEFAULTS.NFT_FARM
const candyCreator = DEFAULTS.LEGENDS_CM
const exiledCreator = DEFAULTS.EXILED_CM

const BlockNftStaking = (props) => {
  const { className, ...rest } = props

  const { setIsLoading } = useOverlay()

  const wallet = useWallet()
  const { connection } = useConnection()
  const { handleGemfarmError, addToastToStack } = useOverlay()
  const navigate = useNavigate()

  const [nfts, setNfts] = useState([])
  const [gb, setGb] = useState()
  const [gf, setGf] = useState()
  const [bank, setBank] = useState()
  const [vault, setVault] = useState()
  const [noFarmer, setNoFarmer] = useState(undefined)
  const [gwInitiated, setGwInitiated] = useState(false)
  const [farmerState, setFarmerState] = useState()

  const handleToggleNftSelection = (id) => {
    setNfts((prevStateNfts) =>
      prevStateNfts?.map((nft) =>
        nft.id === id ? { ...nft, isSelected: !nft.isSelected } : nft
      )
    )
  }

  const deselectAll = () =>
    setNfts((prevStateNfts) =>
      prevStateNfts?.map((nft) => ({ ...nft, isSelected: false }))
    )

  const stakedNfts = nfts?.filter((nft) => nft.isSelected)

  const isStakeFull = stakedNfts.length > 11

  const fetchFarmer = async () => {
    try {
      const [farmerPDA] = await findFarmerPDA(farm, wallet.publicKey)
      const tempFarmerAcc = await gf.fetchFarmerAcc(farmerPDA)
      const tempVaultAcc = await gb.fetchVaultAcc(tempFarmerAcc.vault)

      setBank(tempVaultAcc.bank)
      setVault(tempFarmerAcc.vault)
      setNoFarmer(false)
      setFarmerState(gf.parseFarmerState(tempFarmerAcc))
    } catch (e) {
      console.error('Fetch farmer error', e)
      setNoFarmer(true)
    }
  }

  const initFarmer = async () => {
    setIsLoading(true)
    try {
      await gf.initFarmerWallet(farm)
      await fetchFarmer()
    } catch (e) {
      handleGemfarmError(e)
    }
    setIsLoading(false)
  }

  const populateWalletNFTs = async () => {
    const allNFTs = await getNFTsByOwner(wallet.publicKey, connection)
    const arrangedNFTs = allNFTs
      .filter(
        (nft) =>
          nft.onchainMetadata?.data?.creators?.some(
            (c) =>
              c.verified &&
              (c.address === candyCreator.toString() ||
                c.address === exiledCreator.toString())
          ) && !nft.frozen
      )
      .map((nft) => {
        const edition = nft.externalMetadata.attributes.find(
          (attr) => attr.trait_type === 'EDITION'
        )?.value
        const name = nft.externalMetadata.attributes.find(
          (attr) => attr.trait_type === 'LEGEND'
        )?.value
        const [firstName, lastName] = (name || '').split(' ')
        return {
          id: nft.mint.toString(),
          imgUrl: nft.externalMetadata.image,
          athlete: {
            firstName,
            lastName,
            imgUrl: '/favicon.ico',
          },
          nftId: parseInt(edition, 10),
          // owner: {
          //   username: '',
          //   imgUrl: '',
          // },
          gemNft: nft,
          frozen: nft.frozen,
        }
      })
    setNfts(arrangedNFTs)
  }

  const fetchNft = async (conn, mint) => {
    const mplex = new metaplex.Metaplex(conn)
    return await mplex
      .nfts()
      .findByMint({ mintAddress: mint, loadJsonMetadata: true })
  }

  const prepPnftAccounts = async ({
    nftMetadata,
    nftMint,
    sourceAta,
    destAta,
    authData = null,
  }) => {
    let meta
    let creators = []
    if (nftMetadata) {
      meta = nftMetadata
    } else {
      const nft = await fetchNft(gb.provider.connection, nftMint)
      meta = nft.metadataAddress
      creators = nft.creators.map((c) => c.address)
    }

    const inflatedMeta = await Metadata.fromAccountAddress(
      gb.provider.connection,
      meta
    )

    const ruleSet = inflatedMeta.programmableConfig?.ruleSet

    const [ownerTokenRecordPda, ownerTokenRecordBump] =
      await findTokenRecordPDA(nftMint, sourceAta)
    const [destTokenRecordPda, destTokenRecordBump] = await findTokenRecordPDA(
      nftMint,
      destAta
    )

    //retrieve edition PDA
    const mplex = new metaplex.Metaplex(gb.provider.connection)
    const nftEditionPda = mplex.nfts().pdas().edition({ mint: nftMint })

    //have to re-serialize due to anchor limitations
    const authDataSerialized = authData
      ? {
          payload: Object.entries(authData.payload.map).map(([k, v]) => {
            return { name: k, payload: v }
          }),
        }
      : null

    return {
      meta,
      creators,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      ruleSet,
      nftEditionPda,
      authDataSerialized,
    }
  }

  const buildDepositGemPnft = async (
    bank,
    vault,
    vaultOwner,
    gemAmount,
    gemMint,
    gemSource,
    mintProof,
    creatorProof
  ) => {
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint)
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint)
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault)
    const [gemRarity, gemRarityBump] = await findRarityPDA(bank, gemMint)

    //pnft
    const {
      meta,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      ruleSet,
      nftEditionPda,
      authDataSerialized,
      compute = 400000,
      priorityFee = 1,
    } = await prepPnftAccounts({
      nftMint: gemMint,
      destAta: gemBox,
      authData: null, //currently useless
      sourceAta: gemSource,
    })

    const remainingAccounts = []
    if (ruleSet) {
      remainingAccounts.push({
        pubkey: ruleSet,
        isSigner: false,
        isWritable: false,
      })
    }
    if (mintProof)
      remainingAccounts.push({
        pubkey: mintProof,
        isWritable: false,
        isSigner: false,
      })
    if (creatorProof)
      remainingAccounts.push({
        pubkey: creatorProof,
        isWritable: false,
        isSigner: false,
      })

    const signers = []
    if (isKp(vaultOwner)) signers.push(vaultOwner)

    console.log(
      `depositing ${gemAmount} gems into ${gemBox.toBase58()}, GDR ${GDR.toBase58()} (PNFT)`
    )

    const builder = gb.bankProgram.methods
      .depositGemPnft(
        vaultAuthBump,
        gemRarityBump,
        gemAmount,
        authDataSerialized,
        !!ruleSet
      )
      .accounts({
        bank,
        vault,
        owner: isKp(vaultOwner) ? vaultOwner.publicKey : vaultOwner,
        authority: vaultAuth,
        gemBox,
        gemDepositReceipt: GDR,
        gemSource,
        gemMint,
        gemRarity,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        gemMetadata: meta,
        gemEdition: nftEditionPda,
        ownerTokenRecord: ownerTokenRecordPda,
        destTokenRecord: destTokenRecordPda,
        pnftShared: {
          authorizationRulesProgram: AUTH_PROG_ID,
          tokenMetadataProgram: TMETA_PROG_ID,
          instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        },
      })
      .remainingAccounts(remainingAccounts)
      .signers(signers)

    const [modifyComputeUnits, addPriorityFee] = getTotalComputeIxs(
      compute,
      priorityFee
    )

    const ixs = [
      modifyComputeUnits,
      addPriorityFee,
      await builder.instruction(),
    ]

    return {
      vaultAuth,
      vaultAuthBump,
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      builder,
      ixs,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      meta,
    }
  }

  const depositGem = async (
    bank,
    vault,
    vaultOwner,
    gemAmount,
    gemMint,
    gemSource,
    mintProof,
    creatorProof
  ) => {
    const {
      vaultAuth,
      vaultAuthBump,
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      ixs,
    } = await buildDepositGemPnft(
      bank,
      vault,
      vaultOwner,
      gemAmount,
      gemMint,
      gemSource,
      mintProof,
      creatorProof
    )

    const txSig = await buildAndSendTx({
      connection: gb.provider.connection,
      wallet: gb.provider.wallet,
      ixs,
    })

    return {
      vaultAuth,
      vaultAuthBump,
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      txSig,
    }
  }

  const depositGemWallet = async (
    bank,
    vault,
    gemAmount,
    gemMint,
    gemSource,
    creator
  ) => {
    const [mintProof, bump] = await findWhitelistProofPDA(bank, gemMint)
    const [creatorProof, bump2] = await findWhitelistProofPDA(bank, creator)

    return depositGem(
      bank,
      vault,
      gb.wallet.publicKey,
      gemAmount,
      gemMint,
      gemSource,
      mintProof,
      creatorProof
    )
  }

  const beginStaking = async () => {
    setIsLoading(true)
    try {
      for (const nft of stakedNfts) {
        const verifiedCreator = nft.gemNft.onchainMetadata.data.creators.find(
          (x) => x.verified
        )
        await depositGemWallet(
          bank,
          vault,
          new BN(1),
          nft.gemNft.mint,
          nft.gemNft.pubkey,
          new PublicKey(verifiedCreator.address)
        )
      }
      await gf.stakeWallet(farm)
      addToastToStack({ type: 'nftStakeSuccess' })

      deselectAll()
      navigate('/nft-staked')
    } catch (e) {
      console.error(e)
      handleGemfarmError(e)
      addToastToStack({
        type: 'error',
        text: 'Please apply stake to effectively begin staking',
      })
    }

    await fetchFarmer()
    await populateWalletNFTs()
    setIsLoading(false)
  }

  const addGems = async () => {
    setIsLoading(true)
    try {
      for (const nft of stakedNfts) {
        await gf.flashDepositWallet(
          farm,
          new BN(1),
          nft.gemNft.mint,
          nft.gemNft.pubkey,
          new PublicKey(
            nft.gemNft.onchainMetadata.data.creators.find(
              (x) => x.verified
            ).address
          )
        )
        await fetchFarmer()
      }
      await populateWalletNFTs()

      deselectAll()
      addToastToStack({ type: 'nftStakeSuccess' })
      navigate('/nft-staked')
    } catch (e) {
      handleGemfarmError(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (wallet.connected) {
      initGemFarm(connection, wallet).then(setGf)
      initGemBank(connection, wallet).then(setGb)
    }
  }, [wallet.connected])

  useEffect(() => {
    if (!gf || !gb) return
    setGwInitiated(true)
  }, [gf, gb])

  useEffect(() => {
    if (!gwInitiated) return

    const fn = async () => {
      setIsLoading(true)
      await fetchFarmer()
      await populateWalletNFTs()
      setIsLoading(false)
    }
    fn()
  }, [gwInitiated])

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  return (
    <>
      <SEO
        title="NFT Staking | Blockasset"
        description="Stake your NFTs and earn rewards!"
      />
      <aside>
        <CardSideNav />
        {isDesktop && <CardNFTInfo />}
      </aside>
      <div {...rest} className={classNames(style.blockNftStaking, className)}>
        <div className={style.gridTop}>
          {!isDesktop && <CardNFTInfo />}
          <BannerFeatures />
        </div>
        {/* <BannerYG variant="staking" /> */}
        {wallet.connected && noFarmer && (
          <Button
            variant="neon"
            className={style.becomeFarmerBtn}
            onClick={initFarmer}
            // disabled
          >
            Open Staking Vault
          </Button>
        )}
        {!noFarmer && (
          <div className="grid-asset-hub-nfts">
            {nfts.map(({ gemNft, ...nftData }) => (
              <CardNFT
                key={nftData.id}
                onToggle={handleToggleNftSelection}
                isDisabled={isStakeFull && !nftData.isSelected}
                {...nftData}
              />
            ))}
          </div>
        )}
        {wallet.connected && nfts.length === 0 && (
          <h2 className={classNames('text-joyride-20', style.textHeading)}>
            No NFTs to stake
          </h2>
        )}
        {!wallet.connected && <WalletButton />}
        <Suspense fallback={<div />}>
          <StakeCart
            stakedNfts={stakedNfts}
            handleDeselectAll={deselectAll}
            handleToggleNftSelection={handleToggleNftSelection}
            handleStakeNfts={
              farmerState === 'unstaked' ? beginStaking : addGems
            }
            modalData={[]}
          />
        </Suspense>
      </div>
    </>
  )
}

BlockNftStaking.defaultProps = {
  className: '',
}

BlockNftStaking.propTypes = {
  className: PropTypes.string,
}

export default BlockNftStaking

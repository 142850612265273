import React, { FC } from 'react'
import classNames from 'classnames'

import Icon from '~components/Icon'

import * as style from './CardInfo.module.scss'

export type CardInfoProps = {
  className?: string
  data: {
    label: string
    value: any
    iconName: string
    unit?: string
  }[]
  variant?: 'staked'
}
export const CardInfo: FC<CardInfoProps> = ({
  className = '',
  data,
  variant = 'staked',
  ...rest
}) => (
  <div
    {...rest}
    className={classNames(
      style.cardInfo,
      { [style[variant]]: variant },
      className
    )}
  >
    {data.map(({ iconName, label, value, unit }) => (
      <div
        key={label}
        className={classNames(style.gridDataItem, {
          [style[variant]]: variant,
        })}
      >
        <div
          className={classNames(style.iconWrapper, {
            [style[variant]]: variant,
          })}
        >
          <Icon name={iconName} size={16} />
        </div>
        <h4 className="font-family-syne text-syne-14-bold">{label}</h4>
        <p className="text-joyride-15">
          {value} <span className={style.textUnit}>{unit}</span>
        </p>
      </div>
    ))}
  </div>
)

export default CardInfo

import React, { useContext } from 'react'
import classNames from 'classnames'
import { ToastContainer } from 'react-bootstrap'
import PropTypes from 'prop-types'

import { useOverlay } from '~providers/OverlayStateProvider'

import ToastCustom from '../ToastCustom'
import * as style from './ToastContainer.module.scss'

const ToastContainerCustom = (props) => {
  const { className, ...rest } = props

  const { toastStack } = useOverlay()

  return (
    <ToastContainer
      {...rest}
      className={classNames(style.toastContainer, className)}
    >
      {toastStack.map((toast, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ToastCustom key={index} toast={toast} show />
      ))}
    </ToastContainer>
  )
}

ToastContainerCustom.defaultProps = {
  className: '',
}

ToastContainerCustom.propTypes = {
  className: PropTypes.string,
}

export default ToastContainerCustom

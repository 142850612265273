/* eslint-disable no-await-in-loop,no-unused-vars */
/* eslint-disable no-restricted-syntax */

import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { BN } from '@project-serum/anchor'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { initGemBank } from '~gemworks/gem-bank'
import { initGemFarm } from '~gemworks/gem-farm'
import { getNFTMetadataForMany } from '~utils/NFTget'
import { DEFAULTS } from '~gemworks/globals'
import { findTokenRecordPDA, getTotalComputeIxs } from '~utils/pnft'

import ModalWrapper from '~components/ModalWrapper'
import SEO from '~components/SEO'
import { useOverlay } from '~providers/OverlayStateProvider'
import { buildAndSendTx } from '~utils/transaction'

import CardNFTStaked from './components/CardNFTStaked'
import CardSideNav from '../CardSideNav'
import CardNFTInfo from '../CardNFTInfo'
import BannerYG from '../BannerYG'

import {
  findGdrPDA,
  findGemBoxPDA,
  findRarityPDA,
  findVaultAuthorityPDA,
  isKp,
  findFarmerPDA,
} from '@gemworks/gem-farm-ts'
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import {
  Metadata,
  PROGRAM_ID as TMETA_PROG_ID,
} from '@metaplex-foundation/mpl-token-metadata'
import {
  PublicKey,
  SystemProgram,
  SYSVAR_INSTRUCTIONS_PUBKEY,
} from '@solana/web3.js'
import { PROGRAM_ID as AUTH_PROG_ID } from '@metaplex-foundation/mpl-token-auth-rules'
import * as anchor from '@project-serum/anchor'
import * as metaplex from '@metaplex-foundation/js'

import * as style from './BlockNftStaked.module.scss'

const farm = DEFAULTS.NFT_FARM
const LAMPORTS_PER_ASSET = 10 ** 9

const BlockNftStaked = (props) => {
  const { className, ...rest } = props

  const { setIsLoading } = useOverlay()

  const wallet = useWallet()
  const { connection } = useConnection()
  const { handleGemfarmError } = useOverlay()
  const navigate = useNavigate()

  const [gb, setGb] = useState()
  const [gf, setGf] = useState()
  const [farmerState, setFarmerState] = useState()
  const [availableA, setAvailableA] = useState(0)
  const [availableB, setAvailableB] = useState(0)
  const [bank, setBank] = useState()
  const [vault, setVault] = useState()
  const [farmAcc, setFarmAcc] = useState()
  const [gwInitiated, setGwInitiated] = useState(false)
  const [currentVaultNFTs, setCurrentVaultNFTs] = useState([])
  const [showUnstakeModal, setShowUnstakeModal] = useState(false)

  const assetClaimAmount = availableA / LAMPORTS_PER_ASSET
  const blockClaimAmount = availableB / DEFAULTS.LAMPORTS_PER_BLOCK

  const fetchFarm = async () => {
    const tempFarmAcc = await gf.fetchFarmAcc(farm)
    setFarmAcc(tempFarmAcc)
  }

  const fetchFarmer = async () => {
    try {
      const [farmerPDA] = await findFarmerPDA(farm, wallet.publicKey)
      const tempFarmerAcc = await gf.fetchFarmerAcc(farmerPDA)
      const tempVaultAcc = await gb.fetchVaultAcc(tempFarmerAcc.vault)
      const tempFarmerState = gf.parseFarmerState(tempFarmerAcc)

      setBank(tempVaultAcc.bank)
      setVault(tempFarmerAcc.vault)
      setFarmerState(tempFarmerState)
      setAvailableA(
        tempFarmerAcc.rewardA.accruedReward
          .sub(tempFarmerAcc.rewardA.paidOutReward)
          .toNumber()
      )
      setAvailableB(
        tempFarmerAcc.rewardB.accruedReward
          .sub(tempFarmerAcc.rewardB.paidOutReward)
          .toNumber()
      )
    } catch (e) {
      console.error('Fetch farmer error', e)
    }
  }

  const populateVaultNFTs = async () => {
    const foundGDRs = await gb.fetchAllGdrPDAs(vault)
    if (foundGDRs && foundGDRs.length) {
      const mints = foundGDRs
        .filter((gdr) => gdr.account.vault.toString() === vault.toString())
        .map((gdr) => ({ mint: gdr.account.gemMint }))

      const metadatas = await getNFTMetadataForMany(mints, connection)
      const tempCurrentVaultNFTs = metadatas.map((nft) => {
        const edition = nft.externalMetadata.attributes.find(
          (attr) => attr.trait_type === 'EDITION'
        )?.value
        const name = nft.externalMetadata.attributes.find(
          (attr) => attr.trait_type === 'LEGEND'
        )?.value
        const [firstName, lastName] = (name || ' ').split(' ')
        return {
          id: nft.mint.toString(),
          imgUrl: nft.externalMetadata.image,
          athlete: {
            firstName,
            lastName,
            imgUrl: '/favicon.ico',
          },
          nftId: parseInt(edition, 10),
          // owner: {
          //   username: '',
          //   imgUrl: '',
          // },
          gemNft: nft,
        }
      })

      setCurrentVaultNFTs(tempCurrentVaultNFTs)
    } else {
      setCurrentVaultNFTs([])
    }
  }

  const claim = async () => {
    setIsLoading(true)
    try {
      await gf.claimWallet(
        farm,
        new PublicKey(farmAcc.rewardA.rewardMint),
        new PublicKey(farmAcc.rewardB.rewardMint)
      )
    } catch (e) {
      handleGemfarmError(e)
    }
    await fetchFarmer()
    setIsLoading(false)
  }

  const fetchNft = async (conn, mint) => {
    const mplex = new metaplex.Metaplex(conn)
    return await mplex
      .nfts()
      .findByMint({ mintAddress: mint, loadJsonMetadata: true })
  }

  const prepPnftAccounts = async ({
    nftMetadata,
    nftMint,
    sourceAta,
    destAta,
    authData = null,
  }) => {
    let meta
    let creators = []
    if (nftMetadata) {
      meta = nftMetadata
    } else {
      const nft = await fetchNft(gb.provider.connection, nftMint)
      meta = nft.metadataAddress
      creators = nft.creators.map((c) => c.address)
    }

    const inflatedMeta = await Metadata.fromAccountAddress(
      gb.provider.connection,
      meta
    )

    const ruleSet = inflatedMeta.programmableConfig?.ruleSet

    const [ownerTokenRecordPda, ownerTokenRecordBump] =
      await findTokenRecordPDA(nftMint, sourceAta)
    const [destTokenRecordPda, destTokenRecordBump] = await findTokenRecordPDA(
      nftMint,
      destAta
    )

    //retrieve edition PDA
    const mplex = new metaplex.Metaplex(gb.provider.connection)
    const nftEditionPda = mplex.nfts().pdas().edition({ mint: nftMint })

    //have to re-serialize due to anchor limitations
    const authDataSerialized = authData
      ? {
          payload: Object.entries(authData.payload.map).map(([k, v]) => {
            return { name: k, payload: v }
          }),
        }
      : null

    return {
      meta,
      creators,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      ruleSet,
      nftEditionPda,
      authDataSerialized,
    }
  }

  const buildWithdrawGemPnft = async (
    bank,
    vault,
    vaultOwner,
    gemAmount,
    gemMint,
    receiver,
    compute = 400000,
    priorityFee = 1
  ) => {
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint)
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint)
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault)
    const [gemRarity, gemRarityBump] = await findRarityPDA(bank, gemMint)

    const gemDestination = await gb.findATA(gemMint, receiver)

    const signers = []
    if (isKp(vaultOwner)) signers.push(vaultOwner)

    //pnft
    const {
      meta,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      ruleSet,
      nftEditionPda,
      authDataSerialized,
    } = await prepPnftAccounts({
      nftMint: gemMint,
      destAta: gemDestination,
      authData: null, //currently useless
      sourceAta: gemBox,
    })
    const remainingAccounts = []
    if (ruleSet) {
      remainingAccounts.push({
        pubkey: ruleSet,
        isSigner: false,
        isWritable: false,
      })
    }

    console.log(
      `withdrawing ${gemAmount} gems from ${gemBox.toBase58()}, GDR ${GDR.toBase58()} (PNFT)`
    )
    const builder = gb.bankProgram.methods
      .withdrawGemPnft(
        vaultAuthBump,
        gemBoxBump,
        GDRBump,
        gemRarityBump,
        gemAmount,
        authDataSerialized,
        !!ruleSet
      )
      .accounts({
        bank,
        vault,
        owner: isKp(vaultOwner) ? vaultOwner.publicKey : vaultOwner,
        authority: vaultAuth,
        gemBox,
        gemDepositReceipt: GDR,
        gemDestination,
        gemMint,
        gemRarity,
        receiver,
        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        gemMetadata: meta,
        gemEdition: nftEditionPda,
        destTokenRecord: destTokenRecordPda,
        ownerTokenRecord: ownerTokenRecordPda,
        pnftShared: {
          authorizationRulesProgram: AUTH_PROG_ID,
          tokenMetadataProgram: TMETA_PROG_ID,
          instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
        },
      })
      .signers(signers)
      .remainingAccounts(remainingAccounts)

    const [modifyComputeUnits, addPriorityFee] = getTotalComputeIxs(
      compute,
      priorityFee
    )

    const ixs = [
      modifyComputeUnits,
      addPriorityFee,
      await builder.instruction(),
    ]

    return {
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      vaultAuth,
      vaultAuthBump,
      gemDestination,
      builder,
      ixs,
      ownerTokenRecordBump,
      ownerTokenRecordPda,
      destTokenRecordBump,
      destTokenRecordPda,
      meta,
    }
  }

  const withdrawGem = async (
    bank,
    vault,
    vaultOwner,
    gemAmount,
    gemMint,
    receiver
  ) => {
    const {
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      vaultAuth,
      vaultAuthBump,
      gemDestination,
      ixs,
    } = await buildWithdrawGemPnft(
      bank,
      vault,
      vaultOwner,
      gemAmount,
      gemMint,
      receiver
    )

    const txSig = await buildAndSendTx({
      connection: gb.provider.connection,
      wallet: gb.provider.wallet,
      ixs,
    })

    return {
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      vaultAuth,
      vaultAuthBump,
      gemDestination,
      txSig,
    }
  }

  const withdrawGemWallet = async (bank, vault, gemAmount, gemMint) => {
    return withdrawGem(
      bank,
      vault,
      wallet.publicKey,
      gemAmount,
      gemMint,
      wallet.publicKey
    )
  }

  const endStaking = async () => {
    setIsLoading(true)
    try {
      await gf.unstakeWallet(farm)
      if (
        farmerState === 'pendingCooldown' ||
        (farmerState === 'unstaked' && currentVaultNFTs.length > 0)
      ) {
        for (const nft of currentVaultNFTs) {
          await withdrawGemWallet(bank, vault, new BN(1), nft.gemNft.mint)
        }
      }
    } catch (e) {
      handleGemfarmError(e)
    }
    await fetchFarmer()
    setIsLoading(false)
  }

  const refreshAccount = async () => {
    setIsLoading(true)
    try {
      await gf.refreshFarmerWallet(farm, wallet.publicKey)
      await fetchFarmer()
    } catch (e) {
      handleGemfarmError(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (wallet.connected) {
      initGemFarm(connection, wallet).then(setGf)
      initGemBank(connection, wallet).then(setGb)
    } else {
      navigate('/')
    }
  }, [wallet.connected])

  useEffect(() => {
    if (!gf || !gb) return
    setGwInitiated(true)
  }, [gf, gb])

  useEffect(() => {
    if (!gwInitiated) return

    const fn = async () => {
      setIsLoading(true)
      await fetchFarm()
      await fetchFarmer()
      setIsLoading(false)
    }
    fn()
  }, [gwInitiated])

  useEffect(() => {
    const fn = async () => {
      if (vault) {
        setIsLoading(true)
        await populateVaultNFTs()
        setIsLoading(false)
      }
    }
    fn()
  }, [vault])

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  return (
    <>
      <aside>
        <CardSideNav />
        {isDesktop && <CardNFTInfo />}
      </aside>
      <ModalWrapper
        show={showUnstakeModal}
        onHide={() => setShowUnstakeModal(false)}
        title="Confirm NFT Unstaking"
      >
        <Modal.Body className={style.modalBody}>
          <p>
            You are now unstaking, please approve the transactions, refresh,
            then end staking.
          </p>
          <br />
          <Button
            variant="neon"
            onClick={() => {
              setShowUnstakeModal(false)
              endStaking()
            }}
          >
            Unstake
          </Button>
        </Modal.Body>
      </ModalWrapper>
      <SEO
        title="NFT Staked | Blockasset"
        description="See your Asset and $BLOCK balance from your NFT staking!"
      />
      <div {...rest} className={classNames(style.blockNftStaked, className)}>
        {!isDesktop && <CardNFTInfo variant="staked" />}
        <BannerYG
          variant="staked"
          assetBalance={assetClaimAmount}
          blockBalance={blockClaimAmount}
          claim={claim}
        />
        <div className={style.buttons}>
          {currentVaultNFTs.length > 0 && (
            <>
              {(farmerState === 'staked' || farmerState === 'unstaked') && (
                <Button
                  variant="neon"
                  className={style.btnStake}
                  onClick={() => setShowUnstakeModal(true)}
                >
                  Unstake NFTs
                </Button>
              )}
              {/* {farmerState === 'unstaked' && (
                <Button
                  variant="purple"
                  className={style.btnStake}
                  onClick={async () => {
                    await gf.stakeWallet(farm)
                    await fetchFarmer()
                    await populateVaultNFTs()
                  }}
                >
                  Apply stake
                </Button>
              )} */}
            </>
          )}
          {farmerState === 'pendingCooldown' && (
            <Button
              variant="neon"
              className={style.btnStake}
              onClick={endStaking}
            >
              End staking
            </Button>
          )}
          <Button
            variant="neon"
            className={style.btnStake}
            onClick={refreshAccount}
          >
            Refresh
          </Button>
        </div>
        {currentVaultNFTs.length > 0 ? (
          <div className="grid-asset-hub-nfts">
            {currentVaultNFTs.map(({ gemNft, ...nft }) => (
              <CardNFTStaked key={nft.id} {...nft} />
            ))}
          </div>
        ) : (
          <h2 className={classNames('text-joyride-20', style.textHeading)}>
            No NFTs staked
          </h2>
        )}
      </div>
    </>
  )
}

BlockNftStaked.defaultProps = {
  className: '',
}

BlockNftStaked.propTypes = {
  className: PropTypes.string,
}

export default BlockNftStaked

// extracted by mini-css-extract-plugin
export var blockNftStaked = "BlockNftStaked_blockNftStaked__sRs3R";
export var btnClaim = "BlockNftStaked_btnClaim__8xejo";
export var btnStake = "BlockNftStaked_btnStake__VRF1p";
export var btnWrapper = "BlockNftStaked_btnWrapper__d5sJc";
export var buttons = "BlockNftStaked_buttons__UN4EM";
export var dotty = "BlockNftStaked_dotty__N7Rm6";
export var gridNfts = "BlockNftStaked_gridNfts__mKdx5";
export var iconWrap = "BlockNftStaked_iconWrap__uGjhO";
export var modalBody = "BlockNftStaked_modalBody__YcEk3";
export var rotate = "BlockNftStaked_rotate__KdUYA";
export var textHeading = "BlockNftStaked_textHeading__cG66P";
import RouteURL from '~routes'

export const TOP_NAV_ITEMS: {
  text: string
  href: string
  subItems?: {
    text: string
    href: string
    iconName?: string
    group?: number
    isComingSoon?: boolean
    requiresLogIn?: boolean
  }[]
  isExternal?: boolean
}[] = [
  {
    text: 'ASSET HUB',
    href: RouteURL.LANDING_PAGE,
    subItems: [
      {
        text: 'HOME',
        href: RouteURL.LANDING_PAGE,
        iconName: 'icon-nav-blockasset',
        group: 1,
      },
      {
        text: 'TOKEN STAKING',
        href: RouteURL.TOKEN_STAKING,
        iconName: 'icon-nav-blockasset',
        group: 1,
      },
      {
        text: 'NFT STAKING',
        href: RouteURL.NFT_STAKING,
        iconName: 'icon-nav-nft',
        group: 1,
      },
      {
        text: 'NFT STAKED',
        href: RouteURL.NFT_STAKED,
        requiresLogIn: true,
        iconName: 'icon-nav-nft-staked',
        group: 1,
      },
      // {
      //   text: 'TOKEN STAKING V2',
      //   href: RouteURL.TOKEN_STAKING_V2,
      //   iconName: 'icon-nav-blockasset',
      //   group: 2,
      // },
      // {
      //   text: 'NFT STAKING V2',
      //   href: RouteURL.NFT_STAKING_V2,
      //   iconName: 'icon-nav-nft',
      //   group: 2,
      // },
      // {
      //   text: 'NFT STAKED V2',
      //   href: RouteURL.NFT_STAKED_V2,
      //   iconName: 'icon-nav-nft-staked',
      //   group: 2,
      // },
      // {
      //   text: 'SMESH STAKING',
      //   href: RouteURL.SMESH_STAKING,
      //   iconName: 'icon-nav-nft',
      //   group: 3,
      // },
      // {
      //   text: 'SMESH STAKED',
      //   href: RouteURL.SMESH_STAKED,
      //   iconName: 'icon-nav-nft-staked',
      //   group: 3,
      // },
    ],
  },
  {
    text: 'TRADE',
    href: RouteURL.EXT_SWAP,
    subItems: [
      { text: 'SWAP', href: RouteURL.EXT_SWAP },
      { text: 'LIQUIDITY', href: '/liquidity', isComingSoon: true },
      { text: 'LAUNCHPAD', href: '/launchpad', isComingSoon: true },
      { text: 'MARKETCAP', href: '/marketcap', isComingSoon: true },
    ],
  },
  {
    text: 'NFTs',
    href: RouteURL.EXT_NFT,
    isExternal: true,
  },
  { text: 'MEDIA', href: RouteURL.EXT_MEDIA },
  { text: 'ABOUT', href: RouteURL.EXT_ABOUT },
]

// extracted by mini-css-extract-plugin
export var btnPresent = "CardStakeBlock_btnPresent__VgwKf";
export var btnStake = "CardStakeBlock_btnStake__2Dv+W";
export var btnWrapper = "CardStakeBlock_btnWrapper__W0XHL";
export var cardHeading = "CardStakeBlock_cardHeading__d+luN";
export var cardHeadingContainer = "CardStakeBlock_cardHeadingContainer__7QChj";
export var cardStakeBlock = "CardStakeBlock_cardStakeBlock__SlTs+";
export var cardStats = "CardStakeBlock_cardStats__4+SFu";
export var date = "CardStakeBlock_date__Cv8B1";
export var dotty = "CardStakeBlock_dotty__yAfgJ";
export var gridPercentButtons = "CardStakeBlock_gridPercentButtons__N47l4";
export var hrHeading = "CardStakeBlock_hrHeading__ey3bx";
export var inputRange = "CardStakeBlock_inputRange__jSNvO";
export var inputWrapper = "CardStakeBlock_inputWrapper__8r7ZV";
export var modalBody = "CardStakeBlock_modalBody__vZEQ0";
export var rotate = "CardStakeBlock_rotate__oTwKZ";
export var statsGrid = "CardStakeBlock_statsGrid__gPo--";
export var table = "CardStakeBlock_table__b99Ao";
export var textBalance = "CardStakeBlock_textBalance__iP9Hb";
export var textResult = "CardStakeBlock_textResult__IrRro";
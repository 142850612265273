import RouteURL from '~routes'

export const BOTTOM_NAV: {
  heading: string
  href?: string
  subItems?: {
    text: string
    href: string
    isComingSoon?: boolean
  }[]
  isComingSoon?: boolean
}[] = [
  {
    heading: 'Asset Hub',
    href: RouteURL.LANDING_PAGE,
    subItems: [
      { text: 'Token staking', href: RouteURL.TOKEN_STAKING },
      { text: 'NFT staking', href: RouteURL.NFT_STAKING },
      { text: 'NFT staked', href: RouteURL.NFT_STAKED },
      // { text: 'Token staking v2', href: RouteURL.TOKEN_STAKING_V2 },
      // { text: 'NFT staking v2', href: RouteURL.NFT_STAKING_V2 },
      // { text: 'NFT staked v2', href: RouteURL.NFT_STAKED_V2 },
      // { text: 'Smesh staking', href: RouteURL.SMESH_STAKING },
      // { text: 'Smesh staked', href: RouteURL.SMESH_STAKED },
    ],
  },
  {
    heading: 'Trade',
    subItems: [
      { text: 'Swap', href: RouteURL.EXT_SWAP },
      { text: 'Liquidity', href: '/', isComingSoon: true },
      { text: 'Launchpad', href: '/', isComingSoon: true },
      { text: 'Marketcap', href: '/', isComingSoon: true },
    ],
  },
  {
    heading: 'NFT Marketplace',
    subItems: [
      { text: 'Recently added athletes', href: '/', isComingSoon: true },
      { text: 'Live NFT listing', href: '/', isComingSoon: true },
      { text: 'Teams', href: '/', isComingSoon: true },
    ],
  },
]

export const LINKS = [
  {
    name: 'Terms & Conditions',
    link: 'https://blockasset.co/terms_and_conditions.pdf',
  },
  // {
  //   name: 'Privacy Policy',
  //   link: '#',
  // },
  {
    name: 'Whitepaper',
    link: 'https://blockasset.co/whitepaper',
  },
  {
    name: 'Lightpaper',
    link: 'https://blockasset.co/lightpaper.pdf',
  },
]

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '~components/Icon'
import * as style from './DataItem.module.scss'

const DataItem = ({
  label,
  value,
  iconName,
  variant,
  colorVariant,
  iconProps,
  ...rest
}) => (
  <div
    className={classNames(style.blockStat, {
      [style[variant]]: variant,
    })}
    {...rest}
  >
    <div
      className={classNames(style.iconWrapper, {
        [style[colorVariant]]: colorVariant !== 'primary',
      })}
    >
      <Icon name={iconName} size={24} {...iconProps} />
    </div>

    <h4 className="font-family-syne text-syne-18-bold">{label}</h4>
    <span
      className={classNames(
        'text-joyride-20',
        `text-color-gradient-${colorVariant}`,
        style.textNumber
      )}
    >
      {value}
    </span>
  </div>
)

DataItem.defaultProps = {
  variant: '',
  colorVariant: 'primary',
  iconName: 'percent',
  iconProps: {},
}

DataItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.string,
  colorVariant: PropTypes.string,
  iconName: PropTypes.string,
  iconProps: PropTypes.object,
}

export default DataItem

import { useQuery } from 'react-query'
import { QueryKey, getTokenPrice, REFETCH_INTERVAL } from '~api'

export default function useSolPrice() {
  const { data: solPrice } = useQuery(
    [QueryKey.SOL_PRICE],
    () => getTokenPrice('solana', 'usd'),
    {
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  return solPrice
}

import { useConnection } from '@solana/wallet-adapter-react'
import type { ReactChild } from 'react'
import React, { useContext, useEffect, useState } from 'react'

export interface UTCNowContextValues {
  UTCNow: number
}

const UTCNowContext: React.Context<UTCNowContextValues> =
  React.createContext<UTCNowContextValues>({
    UTCNow: Math.floor(Date.now() / 1000),
  })

export const UTCNowProvider = ({ children }: { children: ReactChild }) => {
  const { connection } = useConnection()
  const [UTCNow, setUTCNow] = useState(Date.now() / 1000)
  const [timeDiff, setTimeDiff] = useState(0)
  useEffect(() => {
    if (connection) {
      ;(async () => {
        const epochInfo = await connection.getEpochInfo()
        const blockTimeInEpoch = await connection.getBlockTime(
          epochInfo.absoluteSlot
        )
        if (blockTimeInEpoch) setTimeDiff(blockTimeInEpoch - Date.now() / 1000)
      })()
    }
  }, [connection])

  useEffect(() => {
    const interval = setInterval(
      (function expirationStringUpdate() {
        setUTCNow(Math.floor(Date.now() / 1000 + timeDiff))
        return expirationStringUpdate
      })(),
      1000
    )
    return () => clearInterval(interval)
  }, [timeDiff])

  return (
    <UTCNowContext.Provider
      value={{
        UTCNow,
      }}
    >
      {children}
    </UTCNowContext.Provider>
  )
}

export function useUTCNow(): UTCNowContextValues {
  const context = useContext(UTCNowContext)
  return context
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { round } from 'lodash'

import InputWithLabel from '~components/InputWithLabel'
import Icon from '~components/Icon'
import BlockassetTokenIcon from '~components/BlockassetTokenIcon'
import { limitMaxValue, maskNumberValue } from '~utils'

import * as style from './CardStakeCalculator.module.scss'

const CardStakeCalculator = (props) => {
  const { className, aprAmount, ...rest } = props

  const MAX_PERIOD = 36525
  const MAX_AMOUNT = 320000000

  const [stakeAmount, setStakeAmount] = useState(2000)
  const [stakePeriod, setStakePeriod] = useState(90)

  const finalAmount = stakeAmount * (1 + (aprAmount / 100 / 365) * stakePeriod)
  const finalAmountString = round(finalAmount, 3).toString()

  return (
    <div
      {...rest}
      className={classNames('card', style.cardStakeCalculator, className)}
    >
      <div className={style.cardHeading}>
        <BlockassetTokenIcon width="24px" height="24px" />
        <h2 className="text-joyride-15">CALCULATE STAKING</h2>
      </div>
      <hr className={classNames('hr-card', style.hrHeading)} />
      <div className={style.blockInputs}>
        <InputWithLabel
          label="Amount to Stake"
          placeholder={0}
          value={stakeAmount}
          onChange={(e) =>
            setStakeAmount(
              limitMaxValue(maskNumberValue(e.target.value), MAX_AMOUNT)
            )
          }
          autoComplete="off"
          inputMode="decimal"
        />
        <div className={style.blockSeparator}>
          <div className={style.blockPlusSign}>
            <Icon name="icon-plus" size={12} />
          </div>
          <hr className={classNames('hr-card', style.hr)} />
        </div>
        <InputWithLabel
          label="Stake period (days)"
          placeholder={0}
          value={stakePeriod}
          onChange={(e) =>
            setStakePeriod(
              limitMaxValue(maskNumberValue(e.target.value), MAX_PERIOD)
            )
          }
          autoComplete="off"
          inputMode="decimal"
        />
      </div>
      <div className={classNames('text-syne-14-bold', style.textLabel)}>
        Total balance after staking
      </div>
      <div
        className={classNames(style.textResult, {
          [style.fontSmaller]: finalAmountString.length > 10,
        })}
      >
        <div>{finalAmountString}</div>
        <div className={style.textBlock}>
          <Icon name="icon-blockasset-logo" isFromInlineDefs size={28} />
          <span>BLOCK</span>
        </div>
      </div>
    </div>
  )
}

CardStakeCalculator.defaultProps = {
  className: '',
}

CardStakeCalculator.propTypes = {
  className: PropTypes.string,
  aprAmount: PropTypes.number.isRequired,
}

export default CardStakeCalculator

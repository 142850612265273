import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'

import { isAbsoluteUrl } from '~utils'
import { TOP_NAV_ITEMS } from '~utils/constants'
import { useOverlay } from '~providers/OverlayStateProvider'

import HeaderNavDropdown from '../HeaderNavDropdown'

const HeaderNavMenu = (props) => {
  const { className, ...rest } = props

  const { showModalComingSoon } = useOverlay()

  return (
    <Nav variant="header" className={className} as="ul" {...rest}>
      {TOP_NAV_ITEMS.map(
        ({
          text,
          href,
          subItems,
          isComingSoon,
          isExternal,
          forceNoDropdown,
        }) => {
          const isAbsoluteLink = isAbsoluteUrl(href)

          return subItems?.length && !forceNoDropdown ? (
            <Nav.Item key={text} as="li" className="nav-item--dropdown">
              <HeaderNavDropdown text={text} subItems={subItems} />
            </Nav.Item>
          ) : (
            <Nav.Item key={text} as="li">
              <Nav.Link
                href={isAbsoluteLink ? href : null}
                to={!isAbsoluteLink ? href : null}
                as={isAbsoluteLink ? 'a' : Link}
                onClick={(e) => {
                  if (isComingSoon) {
                    e.preventDefault()
                    showModalComingSoon(text)
                  }
                }}
                target={isExternal ? '_blank' : null}
              >
                {text}
              </Nav.Link>
            </Nav.Item>
          )
        }
      )}
    </Nav>
  )
}

HeaderNavMenu.defaultProps = {
  className: '',
}

HeaderNavMenu.propTypes = {
  className: PropTypes.string,
}

export default HeaderNavMenu

import React from 'react'
import sprite from './icons.svg'

const param = (param: any) =>
  typeof param === 'object' ? param : [param, param]

export type IconProps = {
  name: string
  size?: string | number | string[] | number[]
  viewbox?: string | number | string[] | number[]
  isFromInlineDefs?: boolean
}

const Icon = React.forwardRef<any, IconProps>(function icon(
  { name, size = [24, 24], viewbox, isFromInlineDefs = false, ...rest },
  ref
) {
  const sizes = param(size)
  const vbox = viewbox ? param(viewbox) : sizes
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={sizes[0]}
      height={sizes[1]}
      viewBox={`0 0 ${vbox[0]} ${vbox[1]}`}
      ref={ref}
      fill="currentColor"
    >
      <use xlinkHref={`${isFromInlineDefs ? '' : sprite}#${name}`} />
    </svg>
  )
})

export default Icon

import { useQuery } from 'react-query'
import { QueryKey, getTokenPrice, REFETCH_INTERVAL } from '~api'

export default function useBlockPrice() {
  const { data: blockPrice } = useQuery(
    [QueryKey.BLOCK_PRICE],
    () => getTokenPrice('blockasset', 'usd'),
    {
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  return blockPrice || 0
}

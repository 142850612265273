export const DROPDOWN_WALLET_ITEMS = [
  {
    key: 'copy',
    text: 'Copy address',
    icon: 'icon-copy',
    active: {
      icon: 'icon-copied',
      text: 'Copied',
    },
  },
  {
    key: 'changeWallet',
    text: 'Change Wallet',
    icon: 'icon-wallet',
    showInMobileMenu: true,
  },
  // {
  //   key: 'account',
  //   text: 'My account',
  //   icon: 'icon-user',
  //   showInMobileMenu: true,
  // },
  // {
  //   key: 'referral',
  //   text: 'Referral Program',
  //   icon: 'icon-referral',
  //   showInMobileMenu: true,
  // },
  {
    key: 'logout',
    text: 'Disconnect',
    icon: 'icon-logout',
    showInMobileMenu: true,
  },
]

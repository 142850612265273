const RouteURL = {
  TOKEN_STAKING: '/token-staking',
  NFT_STAKING: '/nft-staking',
  NFT_STAKED: '/nft-staked',
  // TOKEN_STAKING_V2: '/token-staking-v2',
  // NFT_STAKING_V2: '/nft-staking-v2',
  // NFT_STAKED_V2: '/nft-staked-v2',
  // SMESH_STAKING: '/smesh-staking',
  // SMESH_STAKED: '/smesh-staked',

  LANDING_PAGE: '/',

  EXT_BLOCKASSET_CO: 'https://blockasset.co',
  EXT_MEDIA: 'https://blockasset.co/media',
  EXT_ABOUT: 'https://blockasset.co/about',

  EXT_SWAP: 'https://trade.blockasset.co/swap',

  EXT_NFT: 'https://magiceden.io/creators/blockasset',

  // Unused
  LEGEND: '/legend/',
  PROFILE: '/profile/',
}

export default RouteURL

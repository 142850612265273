import React, { useState, useEffect, useContext, Suspense } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Modal } from 'react-bootstrap'
import { clamp, round } from 'lodash'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { BN } from '@project-serum/anchor'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { findFarmerPDA } from '@gemworks/gem-farm-ts'

import { initGemBank } from '~gemworks/gem-bank'
import { initGemFarm } from '~gemworks/gem-farm'
import { DEFAULTS } from '~gemworks/globals'

import ModalWrapper from '~components/ModalWrapper'
import BlockassetTokenIcon from '~components/BlockassetTokenIcon'
import InputWithLabel from '~components/InputWithLabel'
import InputRange from '~components/InputRange'
import { useOverlay } from '~providers/OverlayStateProvider'
import {
  limitMaxValue,
  maskNumberValue,
  getCountdown,
  getCountdownStr,
} from '~utils'
import Icon from '~components/Icon'

import { STAKE_PERCENT_OPTIONS } from './constants'
import * as style from './CardStakeBlock.module.scss'

const farm = DEFAULTS.BLOCK_FARM
const blockMint = DEFAULTS.BLOCK_MINT

const ModalFeatures = React.lazy(() =>
  import(/* webpackChunkName: "modal-features" */ '../ModalFeatures')
)

const CardStakeBlock = (props) => {
  const { className, aprAmount, setIsLoading, ...rest } = props

  const wallet = useWallet()
  const { connection } = useConnection()
  const { handleGemfarmError } = useOverlay()

  const [stakeAmount, setStakeAmount] = useState(0)
  const [balance, setBalance] = useState(0)

  const [gb, setGb] = useState()
  const [gf, setGf] = useState()
  const [farmerState, setFarmerState] = useState()
  const [availableA, setAvailableA] = useState(0)
  // const [availableB, setAvailableB] = useState()
  const [bank, setBank] = useState()
  const [vault, setVault] = useState()
  const [vaultAcc, setVaultAcc] = useState()
  const [farmAcc, setFarmAcc] = useState()
  const [farmerAcc, setFarmerAcc] = useState()
  const [noFarmer, setNoFarmer] = useState(undefined)
  const [gwInitiated, setGwInitiated] = useState(false)
  const [blockAccount, setBlockAccount] = useState()
  const [blockDecimals, setBlockDecimals] = useState(0)
  const [cooldownStr, setCooldownStr] = useState('')

  const [show, setShow] = useState(false)
  const [title, setTitle] = useState('')
  const [inner, setInner] = useState('')
  const [onConfirm, setOnConfirm] = useState(null)
  const [onConfirmText, setOnConfirmText] = useState('')
  const [onSecondAction, setOnSecondAction] = useState(null)
  const [onSecondActionText, setOnSecondActionText] = useState('')

  const [isModalFeaturesVisible, setIsModalFeaturesVisible] = useState(false)
  const showModalFeatures = () => setIsModalFeaturesVisible(true)
  const hideModalFeatures = () => setIsModalFeaturesVisible(false)

  const cooldownTs = farmerAcc?.cooldownEndsTs.toNumber()
  const earned = availableA / 10 ** blockDecimals
  /* eslint-disable-next-line */
  const inStaking = farmerAcc?.gemsStaked.toNumber() / 10 ** blockDecimals
  const totalWithdrawn =
    /* eslint-disable-next-line */
    farmerAcc?.rewardA.paidOutReward.toNumber() / 10 ** blockDecimals
  /* eslint-disable-next-line */
  const inVault = vaultAcc?.gemCount.toNumber() / 10 ** blockDecimals

  const MAX_STAKE_AMOUNT = 50000
  const minStake = 0
  const maxStake = Math.min(
    balance,
    Math.max(MAX_STAKE_AMOUNT - (inStaking ?? 0), 0)
  )

  const setStakeAmountInPercent = (percent) => {
    setStakeAmount((maxStake * percent) / 100)
  }

  const fetchFarmer = async () => {
    try {
      const [farmerPDA] = await findFarmerPDA(farm, wallet.publicKey)
      const tempFarmerAcc = await gf.fetchFarmerAcc(farmerPDA)
      const tempVaultAcc = await gb.fetchVaultAcc(tempFarmerAcc.vault)

      const tempFarmerState = gf.parseFarmerState(tempFarmerAcc)
      const blockTokens = (
        await connection.getParsedTokenAccountsByOwner(wallet.publicKey, {
          programId: TOKEN_PROGRAM_ID,
        })
      ).value.filter(
        (token) => token.account.data.parsed.info.mint === blockMint.toString()
      )

      const tempBalance = blockTokens.reduce(
        (acc, t) => acc + t.account.data.parsed.info.tokenAmount.uiAmount,
        0
      )

      setBlockAccount(blockTokens[0]?.pubkey)
      setBlockDecimals(
        blockTokens[0]?.account.data.parsed.info.tokenAmount.decimals
      )
      setFarmerAcc(tempFarmerAcc)
      setBank(tempVaultAcc.bank)
      setVault(tempFarmerAcc.vault)
      setVaultAcc(tempVaultAcc)
      setBalance(tempBalance)
      setFarmerState(tempFarmerState)
      setAvailableA(
        tempFarmerAcc.rewardA.accruedReward
          .sub(tempFarmerAcc.rewardA.paidOutReward)
          .toNumber()
      )
      setNoFarmer(false)
    } catch (e) {
      console.error(e)
      setNoFarmer(true)
    }
  }

  const freshStart = async () => {
    const tempGf = await initGemFarm(connection, wallet)
    setGf(tempGf)
    const tempGb = await initGemBank(connection, wallet)
    setGb(tempGb)
  }

  const initFarmer = async () => {
    setIsLoading(true)
    try {
      await gf.initFarmerWallet(farm)
      await fetchFarmer()
    } catch (e) {
      handleGemfarmError(e)
    }
    setIsLoading(false)
  }

  const beginStaking = async (amount) => {
    setIsLoading(true)
    try {
      if (amount > 0 && !!blockAccount) {
        await gb.depositGemWallet(
          bank,
          vault,
          new BN(Math.floor(amount * 10 ** blockDecimals)),
          blockMint,
          blockAccount,
          wallet.publicKey
        )
      }
      await gf.stakeWallet(farm)
    } catch (e) {
      handleGemfarmError(e)
    }
    await fetchFarmer()
    setIsLoading(false)
  }

  const withdrawGems = async () => {
    await gb.withdrawGemWallet(bank, vault, vaultAcc.gemCount, blockMint)
  }

  const endStaking = async () => {
    setIsLoading(true)
    try {
      await gf.unstakeWallet(farm)
      if (farmerState === 'pendingCooldown') await withdrawGems()
    } catch (e) {
      handleGemfarmError(e)
    }
    await fetchFarmer()
    setIsLoading(false)
  }

  const addGems = async (amount) => {
    setIsLoading(true)
    try {
      if (amount > 0 && !!blockAccount) {
        await gf.flashDepositWallet(
          farm,
          new BN(Math.floor(amount * 10 ** blockDecimals)),
          blockMint,
          blockAccount,
          wallet.publicKey
        )
        await fetchFarmer()
      }
    } catch (e) {
      handleGemfarmError(e)
    }
    setIsLoading(false)
  }

  const claim = async () => {
    setIsLoading(true)
    try {
      await gf.claimWallet(
        farm,
        new PublicKey(farmAcc.rewardA.rewardMint),
        new PublicKey(farmAcc.rewardB.rewardMint)
      )
      await fetchFarmer()
    } catch (e) {
      handleGemfarmError(e)
    }
    setIsLoading(false)
  }

  const reStake = async () => {
    const amount = availableA / 10 ** blockDecimals
    await claim()
    if (farmerState === 'unstaked') {
      await beginStaking(amount)
    } else {
      await addGems(amount)
    }
  }

  const refreshAccount = async () => {
    setIsLoading(true)
    try {
      await gf.refreshFarmerWallet(farm, wallet.publicKey)
      await fetchFarmer()
    } catch (e) {
      handleGemfarmError(e)
    }
    setIsLoading(false)
  }

  const fetchFarm = async () => {
    const tempFarmAcc = await gf.fetchFarmAcc(farm)
    setFarmAcc(tempFarmAcc)
  }

  useEffect(() => {
    if (wallet.connected) freshStart()
  }, [wallet.connected])

  useEffect(() => {
    if (!gf || !gb) return
    setGwInitiated(true)
  }, [gf, gb])

  useEffect(() => {
    if (!gwInitiated) return

    const fn = async () => {
      setIsLoading(true)
      await fetchFarm()
      await fetchFarmer()
      setIsLoading(false)
    }
    fn()
  }, [gwInitiated])

  useEffect(() => {
    if (!cooldownTs) return () => {}

    const interval = setInterval(() => {
      if (!cooldownTs) return
      const currentTs = Math.trunc(new Date().getTime() / 1000)
      const diff = cooldownTs - currentTs
      if (diff <= 0) {
        clearInterval(interval)
        setCooldownStr('')
        return
      }
      setCooldownStr(getCountdownStr(getCountdown(diff)))
    }, 1000)
    return () => clearInterval(interval)
  }, [cooldownTs])

  return (
    <>
      <ModalWrapper show={show} onHide={() => setShow(false)} title={title}>
        <Modal.Body className={style.modalBody}>
          <p>{inner}</p>
          <br />
          <div className={style.btnWrapper}>
            {!!onSecondAction && (
              <Button
                variant="neon"
                onClick={() => {
                  setShow(false)
                  onSecondAction()
                }}
              >
                {onSecondActionText || 'Cancel'}
              </Button>
            )}
            {!!onConfirm && (
              <Button
                variant="neon"
                onClick={() => {
                  setShow(false)
                  onConfirm()
                }}
              >
                {onConfirmText || 'Confirm'}
              </Button>
            )}
          </div>
        </Modal.Body>
      </ModalWrapper>
      <Suspense fallback={<div />}>
        <ModalFeatures
          show={isModalFeaturesVisible}
          onHide={hideModalFeatures}
        />
      </Suspense>
      {noFarmer && (
        <div className={classNames('card', style.cardStakeBlock, className)}>
          <Button
            variant="neon"
            onClick={initFarmer}
            // disabled
          >
            Open Staking Vault
          </Button>
        </div>
      )}
      {!noFarmer && (
        <div
          {...rest}
          className={classNames('card', style.cardStakeBlock, className)}
        >
          <div className={style.cardHeading}>
            <BlockassetTokenIcon width="24px" height="24px" />
            <h2 className="text-joyride-15">STAKE BLOCK</h2>
            <button
              type="button"
              onClick={showModalFeatures}
              className={style.btnPresent}
              title="features"
              aria-label="features"
            >
              <Icon name="icon-present" size={21} />
            </button>
            <button
              type="button"
              className={style.btnPresent}
              onClick={refreshAccount}
            >
              <Icon name="icon-reload" size={19} />
            </button>
            <a
              href="https://blockasset.medium.com/block-token-nft-staking-57dbf44cfcd5"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="info" size={20} />
            </a>
          </div>
          <hr className={classNames('hr-card', style.hrHeading)} />
          <div className={style.inputWrapper}>
            <span
              className={classNames(
                'text-syne-13-semi-bold',
                style.textBalance
              )}
            >
              {inStaking >= MAX_STAKE_AMOUNT
                ? 'Max staked limit reached'
                : `${
                    balance > maxStake
                      ? `MAX: ${maxStake}`
                      : `Balance: ${balance}`
                  } BLOCK`}
            </span>
            <InputWithLabel
              placeholder={0}
              label="Amount to Stake"
              value={stakeAmount}
              onChange={(e) =>
                setStakeAmount(
                  limitMaxValue(maskNumberValue(e.target.value), maxStake)
                )
              }
              autoComplete="off"
              inputMode="decimal"
            />
          </div>
          <div className={style.gridPercentButtons}>
            {STAKE_PERCENT_OPTIONS.map(({ percent, label }) => (
              <Button
                key={percent}
                variant="input-style"
                className="btn-white"
                onClick={() => setStakeAmountInPercent(percent)}
              >
                {label ?? `${percent}%`}
              </Button>
            ))}
          </div>
          <p className={classNames('text-joyride-15', style.textResult)}>
            {round(stakeAmount, 3)} BLOCK
          </p>
          <div className={style.inputRange}>
            <InputRange
              min={minStake}
              max={maxStake === 0 ? 0.0000000001 : maxStake}
              step={0.01}
              values={[clamp(stakeAmount, minStake, maxStake)]}
              onChange={(values) => setStakeAmount(values[0])}
            />
          </div>
          {farmerState !== 'unstaked' && (
            <Button
              variant="neon"
              className={style.btnStake}
              onClick={() => {
                addGems(stakeAmount)
                setStakeAmount(0)
              }}
              disabled={stakeAmount === 0}
              // disabled
            >
              Stake BLOCK
            </Button>
          )}
          {farmerState === 'unstaked' && (
            <Button
              variant="neon"
              className={style.btnStake}
              onClick={async () => {
                setShow(true)
                setTitle('Confirm BLOCK staking')
                setInner(
                  'Guaranteed BLOCK token staking yield 30%. There is no minimum staking period to earn rewards. When you unstake a 10 day cooldown period will apply.'
                )
                setOnConfirm(() => () => {
                  beginStaking(stakeAmount)
                  setStakeAmount(0)
                })
                setOnConfirmText('Confirm')
                setOnSecondAction(null)
              }}
              disabled={stakeAmount === 0}
              // disabled
            >
              Stake BLOCK
            </Button>
          )}
        </div>
      )}

      {!noFarmer && farmerAcc && (
        <div
          {...rest}
          className={classNames('card', style.cardStats, className)}
        >
          <div className={style.statsGrid}>
            <div>
              <h5>TOTAL WITHDRAWN</h5>
              <span>
                <b>{totalWithdrawn}</b> BLOCK
              </span>
            </div>
            <div>
              <h5>IN STAKING</h5>
              <span>
                <b>{inStaking}</b> BLOCK
              </span>
            </div>
            <div>
              <h5>TOTAL EARNED</h5>
              <span>
                <b>{earned}</b> BLOCK
              </span>
            </div>
            {inStaking === 0 && inVault > 0 && (
              <div>
                <h5>IN VAULT</h5>
                <span>
                  <b>{inVault}</b> BLOCK
                </span>
              </div>
            )}
          </div>
          <div className={style.btnWrapper}>
            {earned > 0 && (
              <Button
                variant="neon"
                size="sm"
                onClick={() => {
                  if (inStaking >= MAX_STAKE_AMOUNT) {
                    claim()
                    return
                  }

                  setShow(true)
                  setTitle('Maximize your staking yield')

                  setInner(
                    'Restake your rewards now to maximise your BLOCK earnings'
                  )
                  setOnConfirm(() => reStake)
                  setOnConfirmText('RESTAKE REWARDS')
                  setOnSecondAction(() => claim)
                  setOnSecondActionText('CLAIM REWARDS')
                }}
              >
                Claim rewards
              </Button>
            )}
            {farmerState === 'staked' && (
              <Button
                variant="purple"
                size="sm"
                onClick={() => {
                  setShow(true)
                  setTitle('Are you sure you want to unstake?')

                  const now = new Date()
                  const later = new Date(now.setDate(now.getDate() + 10))
                  // setInner(
                  //   <>
                  //     You will enter a 10 day cooldown period and access your
                  //     tokens on:
                  //     <br />
                  //     <span className={style.date}>{later.toDateString()}</span>
                  //   </>
                  // )
                  setOnConfirm(() => endStaking)
                  setOnConfirmText('Confirm')
                  setOnSecondAction(null)
                  setOnSecondActionText('')
                }}
              >
                Unstake BLOCK
              </Button>
            )}
            {farmerState === 'pendingCooldown' && (
              <Button
                variant="neon"
                size="sm"
                onClick={endStaking}
                disabled={cooldownStr !== ''}
              >
                {cooldownStr !== ''
                  ? ['Cooldown ends in:', <br key={1} />, cooldownStr]
                  : 'End cooldown'}
              </Button>
            )}
            {farmerState === 'unstaked' && inVault !== 0 && (
              <>
                <Button
                  variant="neon"
                  size="sm"
                  onClick={async () => {
                    await withdrawGems()
                    await fetchFarmer()
                  }}
                >
                  Unload vault
                </Button>
                <Button variant="purple" size="sm" onClick={beginStaking}>
                  Apply stake
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

CardStakeBlock.defaultProps = {
  className: '',
  setIsLoading: () => {},
}

CardStakeBlock.propTypes = {
  className: PropTypes.string,
  aprAmount: PropTypes.number.isRequired,
  setIsLoading: PropTypes.func,
}

export default CardStakeBlock

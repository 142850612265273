// extracted by mini-css-extract-plugin
export var banner = "BannerYG_banner__egHcc";
export var bannerInner = "BannerYG_bannerInner__dq2Dl";
export var blockGreen = "BannerYG_blockGreen__hSni+";
export var blockInfo = "BannerYG_blockInfo__bFgyI";
export var blockYellow = "BannerYG_blockYellow__s+g1Z";
export var button = "BannerYG_button__-OGIS";
export var dotty = "BannerYG_dotty__9gH0E";
export var landing = "BannerYG_landing__2ivNz";
export var landingTextGray = "BannerYG_landingTextGray__fFQB3";
export var landingValuesGrid = "BannerYG_landingValuesGrid__04Klq";
export var rotate = "BannerYG_rotate__sQcYB";
export var staked = "BannerYG_staked__SkxBU";
export var staking = "BannerYG_staking__OeVTY";
export var textGray = "BannerYG_textGray__8ugrA";
export var textTopStaked = "BannerYG_textTopStaked__ntPcQ";
export var textTopStaking = "BannerYG_textTopStaking__S0rSL";
export var textYellow = "BannerYG_textYellow__OhFlC";
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

export const configs = {
  env: (process.env.REACT_ENV as 'production' | 'development') || 'production',
  network:
    (process.env.REACT_APP_NETWORK as WalletAdapterNetwork) ||
    WalletAdapterNetwork.Mainnet,
  clusterApiUrl:
    process.env.REACT_APP_MAINNET_URL ||
    'https://tame-late-layer.solana-mainnet.quiknode.pro/9ba5fffc6fb26e0d98e41e1c674ad409e1454cf7/',
  blockMintAddress:
    process.env.REACT_APP_BLOCK_MINT_ADDRESS ||
    'NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk',
  usdtMintAddress:
    process.env.REACT_APP_USDT_ADDRESS ||
    'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  usdcMintAddress:
    process.env.REACT_APP_USDC_ADDRESS ||
    'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  cardinalLegendsStakePoolId:
    process.env.REACT_APP_CARDINAL_LEGENDS_STAKE_POOL_ID ||
    '3BZCupFU6X3wYJwgTsKS2vTs4VeMrhSZgx4P2TfzExtP',
  cardinalBlockTokenStakePoolId:
    process.env.REACT_APP_CARDINAL_BLOCK_TOKEN_STAKE_POOL_ID ||
    'jhksrHQqRKBEFuker9buKw4zDDrmENGTTKnUn2QzsUD',
  aprAmount: process.env.REACT_APP_APR_AMOUNT
    ? parseInt(process.env.REACT_APP_APR_AMOUNT)
    : 30,
  cardinalSmeshStakePoolId:
    process.env.REACT_APP_CARDINAL_SMESH_STAKE_POOL_ID ||
    'Bce4Aq4YheBo5hENeoMhjywdvMhEMc8sUh21S87Qv4q6',
}

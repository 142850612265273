import React, { ReactChild, useContext, useState } from 'react'
import { GEMFARM_ERROR_CODES } from '~utils/enums'

export type Toast = {
  title: string
  text: string
}

interface OverlayStateProviderContextValues {
  isLoading: boolean
  setIsLoading: (v: boolean) => void
  isModalConnectWalletVisible: boolean
  setIsModalConnectWalletVisible: (v: boolean) => void
  hideModalConnectWallet: (v: boolean) => void
  showModalConnectWallet: (v: boolean) => void
  isModalComingSoonVisible: boolean
  setIsModalComingSoonVisible: (v: boolean) => void
  hideModalComingSoon: (v: boolean) => void
  showModalComingSoon: (v: boolean) => void
  toastStack: Toast[]
  addToastToStack: (toast: Toast) => void
  handleGemfarmError: (err: any) => void
}

const OverlayStateProviderContext: React.Context<OverlayStateProviderContextValues> =
  React.createContext<OverlayStateProviderContextValues>({
    isLoading: false,
    setIsLoading: () => {},
    isModalConnectWalletVisible: false,
    setIsModalConnectWalletVisible: () => {},
    hideModalConnectWallet: () => {},
    showModalConnectWallet: () => {},
    isModalComingSoonVisible: false,
    setIsModalComingSoonVisible: () => {},
    hideModalComingSoon: () => {},
    showModalComingSoon: () => {},
    toastStack: [],
    addToastToStack: () => {},
    handleGemfarmError: () => {},
  })

export const OverlayStateProvider = ({
  children,
}: {
  children: ReactChild
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalConnectWalletVisible, setIsModalConnectWalletVisible] =
    useState(false)
  const hideModalConnectWallet = () => setIsModalConnectWalletVisible(false)
  const showModalConnectWallet = () => setIsModalConnectWalletVisible(true)

  const [isModalComingSoonVisible, setIsModalComingSoonVisible] =
    useState(false)
  const hideModalComingSoon = () => setIsModalComingSoonVisible(false)
  const showModalComingSoon = (heading: boolean) =>
    setIsModalComingSoonVisible(heading)

  const [toastStack, setToastStack] = useState<Toast[]>([])
  const addToastToStack = (toast: Toast) =>
    setToastStack((prevState) => [...prevState, toast])

  const handleGemfarmError = (error: any) => {
    const errorCode = error.message.split('0x')[1]
    if (!errorCode) {
      setToastStack((prevState) => [
        ...prevState,
        {
          title: 'Error',
          text: error.message,
        },
      ])
      return
    }

    const arrayCode = parseInt(errorCode, 16).toString().split('').map(Number)
    const [programCode, ...arr] = arrayCode

    // Only gemfarm custom error codes
    if (programCode !== 6) {
      setToastStack((prevState) => [
        ...prevState,
        {
          title: 'Error',
          text: error.message,
        },
      ])
      return
    }

    const programErrorCode = Number(arr.join(''))
    const text = GEMFARM_ERROR_CODES[programErrorCode]
    if (!programErrorCode || !text) return

    setToastStack((prevState) => [
      ...prevState,
      {
        title: 'Error',
        text,
      },
    ])
  }

  return (
    <OverlayStateProviderContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isLoading,
        setIsLoading,
        isModalConnectWalletVisible,
        setIsModalConnectWalletVisible,
        hideModalConnectWallet,
        showModalConnectWallet,
        isModalComingSoonVisible,
        setIsModalComingSoonVisible,
        hideModalComingSoon,
        showModalComingSoon,
        toastStack,
        addToastToStack,
        handleGemfarmError,
      }}
    >
      {children}
    </OverlayStateProviderContext.Provider>
  )
}

export const useOverlay = (): OverlayStateProviderContextValues => {
  const context = useContext(OverlayStateProviderContext)
  return context
}

export default OverlayStateProvider

// const TOAST_CONTENTS = {
//   nftStakeSuccess: {
//     heading: 'Success',
//     text: 'Your NFT was staked',
//   },
//   nftUnstakeSuccess: {
//     heading: 'Success',
//     text: 'Your NFT was unstaked',
//   },
//   claimRewardsSuccess: {
//     heading: 'Success',
//     text: 'Rewards claimed successfully',
//   },
//   error: {
//     heading: 'Error',
//   },
// }

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
// import * as copy from 'copy-to-clipboard'

// import Icon from '~components/Icon'

import BlockBalance from '../BlockBalance'
import * as style from './ButtonCopyWalletAddress.module.scss'

const ButtonCopyWalletAddress = (props) => {
  const { className, ...rest } = props

  // const WALLET_ADDRESS = '0xWALLET'

  const btnRef = useRef()

  // const [isCopyConfirmationVisible, setIsCopyConfirmationVisible] =
  //   useState(false)

  // const handleCopy = (fullValue) => {
  //   copy(fullValue, { debug: true })

  //   if (isCopyConfirmationVisible) return

  //   setIsCopyConfirmationVisible(true)
  //   if (btnRef.current) btnRef.current.focus()

  //   setTimeout(() => {
  //     setIsCopyConfirmationVisible(false)

  //     if (btnRef.current) btnRef.current.blur()
  //   }, 1500)
  // }

  return (
    <Button
      {...rest}
      className={classNames(style.buttonCopyWalletAddress, className)}
      variant="secondary"
      ref={btnRef}
      // onClick={() => handleCopy(WALLET_ADDRESS)}
    >
      <BlockBalance />
      {/* <Icon
        name={isCopyConfirmationVisible ? 'icon-copied' : 'icon-copy'}
        size={24}
        className={style.icon}
      /> */}
    </Button>
  )
}

ButtonCopyWalletAddress.defaultProps = {
  className: '',
}

ButtonCopyWalletAddress.propTypes = {
  className: PropTypes.string,
}

export default ButtonCopyWalletAddress

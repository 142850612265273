import React, { FC } from 'react'
import classNames from 'classnames'

import Icon from '~components/Icon'

import * as style from './BlockassetTokenIcon.module.scss'

export interface BlockassetTokenIconProps {
  width: string
  height: string
  className?: string
}

export const BlockassetTokenIcon: FC<BlockassetTokenIconProps> = ({
  width,
  height,
  className,
  ...rest
}) => (
  <div
    className={classNames(style.circleBg, className)}
    {...rest}
    style={{ width, height }}
  >
    <Icon
      name="icon-blockasset-logo"
      isFromInlineDefs
      size={['54%', 'auto']}
      viewbox={[351.6, 406]}
    />
  </div>
)

export default BlockassetTokenIcon

import React, { useRef, FC } from 'react'
import { Modal, ModalProps } from 'react-bootstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '~components/Icon'
import { getBodyScrollbarWidth } from '~utils'

import * as style from './ModalWrapper.module.scss'

export interface ModalWrapperProps extends ModalProps {
  headerClassName?: string
  isLoading?: boolean
}
export const ModalWrapper: FC<ModalWrapperProps> = ({
  title,
  className,
  show,
  onHide,
  backdrop,
  contentClassName,
  headerClassName,
  children,
  isLoading,
  ...rest
}) => {
  const onHideConditional = () => {
    if (!isLoading && onHide) onHide()
  }

  const headerRef = useRef(document.getElementById('header'))

  const handleEnter = () => {
    if (headerRef?.current?.style.paddingRight)
      headerRef.current.style.paddingRight = `${getBodyScrollbarWidth()}px`
  }

  const handleExit = () => {
    if (headerRef?.current?.style.paddingRight)
      headerRef.current.style.paddingRight = `0`
  }

  return (
    <Modal
      {...rest}
      className={className}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onEnter={handleEnter}
      onExited={handleExit}
      backdrop={isLoading ? 'static' : backdrop}
      onHide={onHideConditional}
      contentClassName={contentClassName}
    >
      <>
        <Modal.Header className={headerClassName}>
          <Modal.Title id="contained-modal-title-vcenter" as="h3">
            {title}
          </Modal.Title>
          <button
            className={classNames(style.btnClose, {
              [style.isLoading]: isLoading,
            })}
            type="button"
            onClick={onHideConditional}
          >
            <Icon name="icon-close" size={[24, 24]} />
          </button>
        </Modal.Header>
        {children}
      </>
    </Modal>
  )
}

ModalWrapper.defaultProps = {
  className: '',
  contentClassName: '',
  headerClassName: '',
  backdrop: true,
  isLoading: false,
}

ModalWrapper.propTypes = {}

export default ModalWrapper

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './BannerFeatures.module.scss'
import { BANNER_FEATURES_DATA } from './constants'

const BannerFeatures = (props) => {
  const { className, ...rest } = props

  return (
    <div {...rest} className={classNames(style.bannerFeatures, className)}>
      {BANNER_FEATURES_DATA.map(({ text, imgUrl, extraText }) => (
        <div key={text} className={style.featureItem}>
          <img
            width={72}
            height={72}
            src={imgUrl}
            alt={text}
            className={classNames(style.img, { [style.spanRows]: !!extraText })}
          />
          <h5
            className={classNames('text-joyride-14', style.textMain, {
              [style.extraMargin]: !!extraText,
            })}
          >
            {text}
          </h5>
          {extraText && <span className={style.textExtra}>{extraText}</span>}
        </div>
      ))}
    </div>
  )
}

BannerFeatures.defaultProps = {
  className: '',
}

BannerFeatures.propTypes = {
  className: PropTypes.string,
}

export default BannerFeatures

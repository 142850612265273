import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import RouteURL from '~routes'
import { Link } from 'react-router-dom'
import { useWallet } from '@solana/wallet-adapter-react'

import { TOP_NAV_ITEMS } from '~utils/constants'

import * as style from './NavTransparent.module.scss'

const NavTransparent = (props) => {
  const { className, ...rest } = props

  const { subItems } = TOP_NAV_ITEMS[0]
  const list = subItems.filter(({ href }) => href !== RouteURL.LANDING_PAGE)

  const { connected: isLoggedIn } = useWallet()

  return (
    <ul {...rest} className={classNames(style.navTransparent, className)}>
      {list.map(({ text, href, requiresLogIn }) => (
        <li key={text}>
          <Link
            to={href}
            className={classNames({
              [style.disabled]: requiresLogIn && !isLoggedIn,
            })}
          >
            {text}
          </Link>
        </li>
      ))}
    </ul>
  )
}

NavTransparent.defaultProps = {
  className: '',
}

NavTransparent.propTypes = {
  className: PropTypes.string,
}

export default NavTransparent

import React, { Suspense, useContext } from 'react'
import PropTypes from 'prop-types'
// import { Container } from 'react-bootstrap'
// import { useLocation } from 'react-router-dom'
// import { trim } from 'lodash'

import Header from '~components/Header'
import Footer from '~components/Footer'

import SVGDefs from '~components/SVGDefs'
import { useOverlay } from '~providers/OverlayStateProvider'

// import SubNavMenu from './components/SubNavMenu'
import ToastContainerCustom from './components/ToastContainer'
// import { MENU_SUB_ITEMS } from './constants'

import * as style from './style.module.scss'

const ModalConnectWallet = React.lazy(() =>
  import(
    /* webpackChunkName: "modal-connect-wallet" */ './components/ModalConnectWallet'
  )
)

const ModalComingSoon = React.lazy(() =>
  import(
    /* webpackChunkName: "modal-connect-wallet" */ './components/ModalComingSoon'
  )
)

const Layout = ({ children }) => {
  const {
    isModalConnectWalletVisible,
    hideModalConnectWallet,
    isModalComingSoonVisible,
    hideModalComingSoon,
  } = useOverlay()

  return (
    <div className={style.layout}>
      <SVGDefs />
      <Suspense fallback={<div />}>
        <ModalConnectWallet
          show={isModalConnectWalletVisible}
          onHide={hideModalConnectWallet}
        />
      </Suspense>
      <Suspense fallback={<div />}>
        <ModalComingSoon
          showHeading={isModalComingSoonVisible}
          onHide={hideModalComingSoon}
        />
      </Suspense>
      <ToastContainerCustom />
      <Header />
      <main className={style.main}>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

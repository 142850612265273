import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'

import SEO from '~components/SEO'
import NavTransparent from './components/NavTransparent'

import * as style from './LandingPage.module.scss'
import CardVideo from './components/CardVideo'
import BannerYG from '../AssetHub/components/BannerYG'

const LandingPage = (props) => {
  const { className, ...rest } = props

  return (
    <Container {...rest} className={classNames(style.landingPage, className)}>
      <SEO
        title="Start Staking Crypto & NFTs | Blockasset"
        description="Let your Crypto & NFTs earn rewards while staked with us, up to 30% APR!"
      />
      <div className={style.gridVideo}>
        <div className={style.gridText}>
          <h1>ASSET HUB</h1>
          <p className="text-syne-16-semi-bold">
            STAKE BLOCK AND LEGENDS NFT
            <br /> TO EARN REWARDS
          </p>
        </div>
        <NavTransparent className={style.nav} />
        <CardVideo
          videoUrl="https://www.youtube.com/watch?v=jYHGK-8G-aE"
          thumbnailUrl="https://i3.ytimg.com/vi/jYHGK-8G-aE/maxresdefault.jpg"
          className={style.cardVideo}
        />
      </div>
      <BannerYG className={style.banner} variant="landing" />
    </Container>
  )
}

LandingPage.defaultProps = {
  className: '',
}

LandingPage.propTypes = {
  className: PropTypes.string,
}

export default LandingPage

const SOCIAL_LINKS = [
  {
    name: 'twitter',
    link: 'https://twitter.com/blockassetco',
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/blockasset/',
  },
  {
    name: 'medium',
    link: 'https://blockasset.medium.com/',
  },
  {
    name: 'telegram',
    link: 'https://t.me/blockassetofficial/',
  },
  {
    name: 'discord',
    link: 'https://discord.com/invite/blockasset',
  },
  {
    name: 'tiktok',
    link: 'https://www.tiktok.com/@blockassetco',
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/blockasset',
  },
]

export default SOCIAL_LINKS

import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'

import DataItem from '../BlockTokenStaking/components/DataItem'
import * as style from './CardTokenInfo.module.scss'
import { round } from 'lodash'
import { configs } from '~configs'
import { kM } from '~utils'
import { useQuery } from 'react-query'
import { QueryKey, REFETCH_INTERVAL } from '~api'
import { initGemFarm } from '~gemworks/gem-farm'
import { DEFAULTS } from '~gemworks/globals'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import useBlockPrice from '~hooks/useBlockPrice'

export type CardTokenInfoProps = {
  className?: string
}

export const CardTokenInfo: FC<CardTokenInfoProps> = ({ className }) => {
  const [totalValueLockedV1, setTotalValueLockedV1] = useState(0)

  const blockPrice = useBlockPrice()

  const { connection } = useConnection()
  const wallet = useWallet()
  const APR_AMOUNT = configs.aprAmount

  const { data: gemsStaked } = useQuery(
    [QueryKey.BLOCKCHAIN_TOKEN_GEMS_STAKED, blockPrice],
    async () => {
      const tempGf = await initGemFarm(connection, wallet as any)
      const tempFarmAcc = await tempGf.fetchFarmAcc(DEFAULTS.BLOCK_FARM)
      return tempFarmAcc.gemsStaked.toNumber()
    },
    {
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  useEffect(() => {
    if (!blockPrice || !gemsStaked) return

    setTotalValueLockedV1(
      (gemsStaked / DEFAULTS.LAMPORTS_PER_BLOCK) * blockPrice
    )
  }, [blockPrice, gemsStaked])

  const dailyRoi = round(APR_AMOUNT / 365, 3)
  const totalValueLockedStr = `$${
    totalValueLockedV1 ? kM(totalValueLockedV1 || 0) : '--'
  }`

  const data = [
    {
      label: 'APR',
      value: `${APR_AMOUNT}%`,
      variant: 'scalable',
      iconName: 'percent',
    },
    {
      label: 'DAILY ROI',
      value: `${dailyRoi}%`,
      iconName: 'trend',
      variant: 'scalable',
    },
    {
      label: 'TOTAL VAL. LOCKED',
      value: totalValueLockedStr,
      iconName: 'trend',
      variant: 'scalable',
    },
  ]
  return (
    <div className={classNames(style.cardTokenInfo, className)}>
      <div className={classNames(style.baseLayer, className)}>
        {data.map((item) => (
          <DataItem key={item.label} {...(item as any)} />
        ))}
      </div>
    </div>
  )
}

export default CardTokenInfo

// extracted by mini-css-extract-plugin
export var btnClaim = "CardNFTStaked_btnClaim__f+sE6";
export var cardNft = "CardNFTStaked_cardNft__OFcwF";
export var cardUnstake = "CardNFTStaked_cardUnstake__1JUdZ";
export var dotty = "CardNFTStaked_dotty__D6yPM";
export var gridAthlete = "CardNFTStaked_gridAthlete__8425g";
export var imgAspectRatioContainer = "CardNFTStaked_imgAspectRatioContainer__-6PW1";
export var imgAthlete = "CardNFTStaked_imgAthlete__BjS01";
export var imgNft = "CardNFTStaked_imgNft__VYMTD";
export var imgOverlay = "CardNFTStaked_imgOverlay__WxSMA";
export var imgOwner = "CardNFTStaked_imgOwner__FMz1H";
export var rotate = "CardNFTStaked_rotate__uPsgH";
export var table = "CardNFTStaked_table__wtXLb";
export var textAthleteName = "CardNFTStaked_textAthleteName__f96qe";
export var textGray = "CardNFTStaked_textGray__y5Lga";
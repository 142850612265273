import React, { FC } from 'react'
import classNames from 'classnames'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useWallet } from '@solana/wallet-adapter-react'

import { TOP_NAV_ITEMS } from '~utils/constants'

import NavSelect from './components/NavSelect'
import BlockTokenPrice from './components/BlockTokenPrice'
import * as style from './CardSideNav.module.scss'

export type CardSideNavProps = {
  className?: string
}
export const CardSideNav: FC<CardSideNavProps> = ({ className = '' }) => {
  const { connected: isLoggedIn } = useWallet()

  const isBtwMdLg = useMediaQuery({
    query: '(min-width: 640px) and (max-width: 1023.98px)',
  })

  const { subItems } = TOP_NAV_ITEMS[0]

  return (
    <div className={classNames('card', className)}>
      <div className={style.innerContent}>
        <BlockTokenPrice />
        {!isBtwMdLg ? (
          <>
            <hr className="hr-card" />
            <Nav variant={'aside' as any} activeKey="">
              {subItems?.map(({ text, href, requiresLogIn, group }, index) => (
                <React.Fragment key={text}>
                  <Nav.Link
                    as={NavLink}
                    end
                    to={href}
                    disabled={requiresLogIn && !isLoggedIn}
                  >
                    {text}
                  </Nav.Link>
                  {group !== (subItems?.[index + 1]?.group ?? group) && (
                    <hr className="hr-card" />
                  )}
                </React.Fragment>
              ))}
            </Nav>
          </>
        ) : (
          <NavSelect />
        )}
      </div>
    </div>
  )
}

export default CardSideNav

import React, { FC } from 'react'
import LoadingOverlay, { LoadingOverlayProps } from 'react-loading-overlay'

// export interface AppLoadingOverlayProps extends LoadingOverlayProps {
// //   children: any
// }

export const AppLoadingOverlay: FC<LoadingOverlayProps> = ({
  children,
  ...rest
}) => <LoadingOverlay {...rest}>{children}</LoadingOverlay>

export default AppLoadingOverlay

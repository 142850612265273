import React, { useEffect, useState } from 'react'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { configs } from '~configs'
import {
  AccountInfo,
  ParsedAccountData,
  PublicKey,
  RpcResponseAndContext,
} from '@solana/web3.js'

export type TokenBalance = {
  amount: number
  mint: PublicKey
  account: PublicKey
}

const findBalance = (
  parsedTokens: RpcResponseAndContext<
    {
      pubkey: PublicKey
      account: AccountInfo<ParsedAccountData>
    }[]
  >,
  mintAddress: string
) => {
  const balances = parsedTokens.value
    .filter((token) => token.account.data.parsed.info.mint === mintAddress)
    .map((token) => ({
      amount: Number(token.account.data.parsed.info.tokenAmount.uiAmount),
      mint: new PublicKey(token.account.data.parsed.info.mint),
      account: token.pubkey,
    }))
  return balances?.length ? balances[0] : null
}

export const useTokenBalance = () => {
  const [refresh, setRefresh] = useState(false)
  const [blockBalance, setBlockBalance] = useState<TokenBalance | null>(null)
  const [usdtBalance, setUsdtBalance] = useState<TokenBalance | null>(null)
  const [usdcBalance, setUsdcBalance] = useState<TokenBalance | null>(null)

  const { publicKey } = useWallet()
  const { connection } = useConnection()

  useEffect(() => {
    ;(async () => {
      if (!connection || !publicKey) {
        setBlockBalance(null)
        setUsdtBalance(null)
        setUsdcBalance(null)
        return
      }

      try {
        const parsedTokens = await connection.getParsedTokenAccountsByOwner(
          publicKey,
          {
            programId: TOKEN_PROGRAM_ID,
          }
        )

        setBlockBalance(findBalance(parsedTokens, configs.blockMintAddress))
        setUsdtBalance(findBalance(parsedTokens, configs.usdtMintAddress))
        setUsdcBalance(findBalance(parsedTokens, configs.usdcMintAddress))
      } catch (e: any) {
        console.error('web3auth failed to get token balance.', e)
        setBlockBalance(null)
        setUsdtBalance(null)
        setUsdcBalance(null)
      }
    })()
  }, [publicKey, refresh])

  return {
    blockBalance,
    usdtBalance,
    usdcBalance,
    refresh: () => setRefresh((x) => !x),
  }
}
